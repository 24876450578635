import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createAIInterviewRecordingEntry } from '../../services/api';
import { FaMicrophone, FaVideo, FaVolumeUp, FaSpinner } from 'react-icons/fa';
const CodingInterviewStartPage = ({ isConnected, startRecording, sendStartMessage, setScreen }) => {
  const [devices, setDevices] = useState({
    mic: false,
    speaker: false,
    camera: false
  });
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [interviewId, setInterviewId] = useState(null);
  const [email, setEmail] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const interviewId = searchParams.get('id');
    const email = localStorage.getItem('email');
    if (interviewId && email) {
      setInterviewId(interviewId);
      setEmail(email);
    }
  }, [location]);
  const checkMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true});
      stream.getTracks().forEach(track => track.stop());
      setDevices(prev => ({ ...prev, mic: true }));
    } catch (err) {
      setDevices(prev => ({ ...prev, mic: false }));
    }
  };

  const checkCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        }
      });
      setStream(mediaStream);
      setDevices(prev => ({ ...prev, camera: true }));
    } catch (err) {
      console.log(err)
      setDevices(prev => ({ ...prev, camera: false }));
    }
  };

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  const checkSpeakers = async () => {
    try {
      const audio = new Audio('data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==');
      await audio.play();
      setDevices(prev => ({ ...prev, speaker: true }));
    } catch (err) {
      setDevices(prev => ({ ...prev, speaker: false }));
    }
  };

  const allDevicesChecked = devices.mic && devices.speaker;

  const startInterview = async () => {
    if (allDevicesChecked && isConnected) {
      try
      {
        await startRecording();
        await sendStartMessage();

      }
      catch(err)
      {

      }
    //   await createAIInterviewRecordingEntry(email, interviewId);
      setScreen('interview');
    }
  };

  return (
    <div className="min-h-screen bg-background">
      {/* Header */}
      <div className="bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white">
        <div className="container mx-auto px-4 sm:px-6 py-8 sm:py-12">
          <h1 className="text-3xl font-bold mb-2">Technical Interview Setup</h1>
          <p className="text-white/80">Complete the system check before starting your interview</p>
        </div>
      </div>

      {/* Connection Status Bar */}
      {!isConnected && (
        <div className="bg-card border-b border-[var(--border-color)]">
          <div className="container mx-auto px-4 sm:px-6 py-3">
            <div className="flex items-center gap-3">
              <FaSpinner className="animate-spin text-[var(--dark-color)]" />
              <div className="flex-1">
                <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-[var(--dark-color)] animate-progress rounded-full"></div>
                </div>
              </div>
              <span className="text-sm text-gray-600">Connecting to server...</span>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="container mx-auto px-4 sm:px-6 py-8">
        <div className="grid lg:grid-cols-2 gap-8">
          {/* Camera Preview Card */}
          <div className="bg-card rounded-xl  shadow-sm overflow-hidden">
            <div className="p-6 border-b border-[var(--border-color)]">
              <h2 className="text-xl font-semibold text-[var(--dark-color)]">Camera Preview</h2>
            </div>
            <div className="aspect-video bg-gray-900 relative">
              {devices.camera ? (
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  muted
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="absolute inset-0 flex items-center justify-center text-white/60">
                  <span>Camera preview will appear here</span>
                </div>
              )}
            </div>
          </div>

          {/* Device Checks */}
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-[var(--dark-color)]">System Check</h2>

            {/* Device Check Cards */}
            {[
              {
                icon: <FaMicrophone />,
                title: 'Microphone',
                description: 'Check your microphone connection',
                isChecked: devices.mic,
                onCheck: checkMicrophone
              },
              {
                icon: <FaVolumeUp />,
                title: 'Speakers',
                description: 'Test your audio output',
                isChecked: devices.speaker,
                onCheck: checkSpeakers
              },
              {
                icon: <FaVideo />,
                title: 'Camera',
                description: 'Enable your camera access',
                isChecked: devices.camera,
                onCheck: checkCamera
              }
            ].map((device, index) => (
              <div key={index} className="bg-card rounded-xl border border-[var(--border-color)] shadow-sm hover:shadow-md transition-all duration-300">
                <div className="p-6 flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-[var(--dark-color)]/10 rounded-xl text-[var(--dark-color)]">
                      {device.icon}
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">{device.title}</h3>
                      <p className="text-sm text-gray-500">{device.description}</p>
                    </div>
                  </div>
                  <button
                    onClick={device.onCheck}
                    className={`px-6 py-3 rounded-lg transition-all duration-300 ${device.isChecked
                        ? 'bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white hover:opacity-90'
                        : 'border border-[var(--border-color)] hover:bg-gray-50 text-gray-700'
                      }`}
                  >
                    {device.isChecked ? 'Connected ✓' : `Check ${device.title}`}
                  </button>
                </div>
              </div>
            ))}

            {/* Warning Message */}
            {!allDevicesChecked && (
              <div className="p-4 bg-yellow-50 rounded-xl border border-yellow-200">
                <div className="flex items-center text-yellow-700">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                  <span>Please check all your devices before starting the interview</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Start Button */}
        <div className="mt-8">
          <button
            onClick={startInterview}
            disabled={!allDevicesChecked || !isConnected}
            className={`w-full py-4 rounded-xl text-white font-medium text-lg transition-all duration-300 ${allDevicesChecked && isConnected
              ? 'bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] hover:opacity-90 transform hover:-translate-y-0.5'
              : 'bg-gray-300 cursor-not-allowed'
              }`}
          >
            {!isConnected
              ? 'Connecting to Server...'
              : allDevicesChecked
                ? 'Start Interview'
                : 'Complete System Check to Continue'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodingInterviewStartPage;