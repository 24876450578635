export const runCode = async (code, input,language) => {
    const languageIdMap = {
        javascript: 102,
        python: 100,
        cpp: 52,
        java: 91,
    };

    // Function to encode text in Base64
    const encodeBase64 = (text) => {
        return btoa(unescape(encodeURIComponent(text)));
    };

    // Function to decode Base64 to text
    const decodeBase64 = (base64Text) => {
        return decodeURIComponent(escape(atob(base64Text)));
    };

    try {
        // Encode the source code and input in Base64 if necessary
        const encodedSourceCode = encodeBase64(code);
        const encodedInput = encodeBase64(input);

        const response = await fetch('https://judge0-ce.p.rapidapi.com/submissions?base64_encoded=true&wait=true&fields=*', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-RapidAPI-Key': '220166fbb9msh9c3962e9ce4d48dp16162ajsn9eebbad2d991', // Add your RapidAPI key here
                'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
            },
            body: JSON.stringify({
                source_code: encodedSourceCode, // Send Base64 encoded code
                language_id: languageIdMap[language],
                stdin: encodedInput, // Pass Base64 encoded input to Judge0
            }),
        });

        const data = await response.json();
        console.log(data);

        if (data.token) {
            // Use synchronous execution to get the result immediately
            const result = data; // Since we set 'wait: true', the result is directly available

            // Decode the output from Base64 and process it
            const decodedOutput = decodeBase64(result.stdout || result.stderr || 'No output returned');

            // Return the result as a JSON object containing output
            return {
                success: true,
                output: decodedOutput,
            };
        } else {
            return {
                success: false,
                error: 'Error: No token received',
            };
        }
    } catch (error) {
        console.error('Error running code:', error);
        return {
            success: false,
            error: 'Error running the code.',
        };
    }
};
