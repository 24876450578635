// src/utils/base64.js
export function base64ToUint8Array(base64) {
    // Remove data URL prefix if present
    const cleanBase64 = base64.replace(/^data:audio\/\w+;base64,/, '');

    const binaryString = window.atob(cleanBase64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
}

export const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};
// src/utils/audioUtils.js

// src/utils/audioUtils.js

/**
 * Converts Float32Array samples to PCM16 format.
 * @param {Float32Array} buffer - The audio samples in Float32 format.
 * @returns {Int16Array} - The audio samples in PCM16 format.
 */
export const bufferToPCM16 = (buffer) => {
    const pcm16Buffer = new Int16Array(buffer.length);
    for (let i = 0; i < buffer.length; i++) {
        let s = Math.max(-1, Math.min(1, buffer[i]));
        pcm16Buffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
    }
    return pcm16Buffer;
};

/**
 * Converts an ArrayBuffer to a Base64 string.
 * @param {ArrayBuffer} buffer - The buffer to convert.
 * @returns {string} - The Base64 encoded string.
 */
export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

// src/utils/audioUtils.js

/**
 * Converts a base64 string to an ArrayBuffer.
 * @param {string} base64 - The base64 encoded string.
 * @returns {ArrayBuffer}
 */
// src/utils/audioUtils.js

/**
 * Converts a base64 string to an ArrayBuffer.
 * @param {string} base64 - The base64 encoded string.
 * @returns {ArrayBuffer}
 */
export const base64ToArrayBuffer = (base64) => {
    try {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    } catch (error) {
        console.error('Error converting base64 to ArrayBuffer:', error);
        throw error;
    }
};

/**
 * Decodes an ArrayBuffer into an AudioBuffer.
 * @param {ArrayBuffer} arrayBuffer - The audio data in ArrayBuffer format.
 * @returns {Promise<AudioBuffer>}
 */
export const decodeAudioData = async (arrayBuffer) => {
    try {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        return await audioContext.decodeAudioData(arrayBuffer);
    } catch (error) {
        console.error('Error decoding audio data:', error);
        throw error;
    }
};

