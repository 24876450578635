import React, { useEffect, useState } from 'react';
import { FaChartBar, FaVideo } from 'react-icons/fa';
import CandidateAnalysis from './InterviewAnalysis';
import AIInterviewPlayer from './VideoAnalysis';
import { useLocation } from 'react-router-dom';
import { getConversations } from '../../services/api';
import ConversationVisualizer from './Conversations';

const ReportPage = () => {
    const [activeTab, setActiveTab] = useState('analysis');
    const [conversations, setConversations] = useState([]);
    const [interviewId, setInterviewId] = useState(null)
    const [email, setEmail] = useState(null)
    const location = useLocation();
    useEffect(() => {
        const parms = new URLSearchParams(location.search)
        let interviewId = parms.get('interviewId')
        let email = parms.get('email')
        setEmail(email)
        setInterviewId(interviewId)

    }, [])
    useEffect(() => {
        const fetchConversations = async () => {
            try {
                let response = await getConversations(interviewId, email)
                if (response.success) {
                    setConversations(response.conversations)

                }
            }
            catch (err) {
                console.error("Error fetching conversations:", err)
            }
        }
        interviewId && email && fetchConversations()
    }, [interviewId, email])
    return (
        <div className="min-h-screen bg-background">
            <div className="bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white">
                <div className="container mx-auto px-4 sm:px-6 py-8 sm:py-12">
                    <div className="flex justify-center">
                        <div className="flex gap-4">
                            <button
                                onClick={() => setActiveTab('video')}
                                className={`px-6 py-3 rounded-xl flex items-center gap-2 transition-all duration-300 ${activeTab === 'video'
                                    ? 'bg-white text-[var(--dark-color)]'
                                    : 'bg-white/10 hover:bg-white/20'
                                    }`}
                            >
                                <FaVideo className={activeTab === 'video' ? 'text-[var(--dark-color)]' : ''} />
                                <span className="font-medium">Interview Recording</span>
                            </button>
                            <button
                                onClick={() => setActiveTab('analysis')}
                                className={`px-6 py-3 rounded-xl flex items-center gap-2 transition-all duration-300 ${activeTab === 'analysis'
                                    ? 'bg-white text-[var(--dark-color)]'
                                    : 'bg-white/10 hover:bg-white/20'
                                    }`}
                            >
                                <FaChartBar className={activeTab === 'analysis' ? 'text-[var(--dark-color)]' : ''} />
                                <span className="font-medium">Analysis</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="container mx-auto px-4 sm:px-6 py-8">
                <div className="transition-all duration-300 ease-in-out">
                    {activeTab === 'video' ? (
                        <div className="animate-fadeIn">
                            {interviewId && email && <AIInterviewPlayer interviewId={interviewId} email={email} />}
                            {/* {conversations.length>0 && <ConversationVisualizer conversations={conversations} />} */}
                        </div>
                    ) : (
                        <div className="animate-fadeIn">
                            {interviewId && email && <CandidateAnalysis interviewId={interviewId} email={email} />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportPage;