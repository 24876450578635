// src/utils/utils.js

import axios from 'axios';

/**
 * Compresses an image blob to the specified quality.
 * @param {Blob} imageBlob - The original image blob.
 * @param {number} quality - Compression quality (0 to 1). Default is 0.2 (20%).
 * @returns {Promise<Blob>} - A promise that resolves to the compressed image blob.
 */
export const compressImage = (imageBlob, quality = 0.2) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(imageBlob);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Image compression failed.'));
          }
          URL.revokeObjectURL(url);
        },
        'image/jpeg',
        quality
      );
    };

    img.onerror = (err) => {
      reject(err);
      URL.revokeObjectURL(url);
    };

    img.src = url;
  });
};

/**
 * Sends the compressed image blob to the backend server.
 * @param {Blob} compressedBlob - The compressed image blob.
 * @returns {Promise<any>} - A promise that resolves to the server's response.
 */
export const sendCompressedImage = async (compressedBlob) => {
  
};
