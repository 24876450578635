export default function ThankYouPage(){
  const handleBackToHome = () => {
    console.log('Navigate to home');
  };

  return (
    <div className="min-h-screen bg-[rgba(108,92,231,0.1)] flex flex-col items-center justify-center p-8">
      <div className="w-full max-w-3xl bg-white rounded-xl border-2 border-[#5849c4] p-12 text-center transition-all duration-300 hover:border-[#4f46e5]">
        <div className="flex justify-center mb-10">
          <div className="relative">
            <div className="absolute inset-0 animate-ping bg-[rgba(108,92,231,0.2)] rounded-full"></div>
            <svg
              className="w-24 h-24 text-[#4f46e5] relative"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>

        <h1 className="text-5xl font-bold text-[#4f46e5] mb-6">
          Thank You!
        </h1>

        <p className="text-2xl text-gray-600 mb-10">
          Your interview has been successfully submitted. We're excited to review your responses!
        </p>

        <div className="space-y-8">
          <div className="p-8 bg-[rgba(108,92,231,0.1)] rounded-xl border-2 border-[#5849c4] transition-all duration-300 hover:border-[#4f46e5]">
            <h2 className="text-xl font-semibold text-[#433cca] mb-6 flex items-center justify-center">
              <svg
                className="w-6 h-6 mr-2"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              What happens next?
            </h2>
            <ul className="text-lg text-gray-600 space-y-4">
              {[
                'Our team will carefully review your interview responses',
                'You will receive detailed feedback via email within 2-3 business days',
                `If selected, we'll reach out to schedule a follow-up discussion`
              ].map((item, index) => (
                <li key={index} className="flex items-center">
                  <span className="w-8 h-8 rounded-full bg-[#4f46e5] text-white flex items-center justify-center text-lg mr-4 flex-shrink-0">
                    {index + 1}
                  </span>
                  {item}
                </li>
              ))}
            </ul>
          </div>

          <button
            onClick={handleBackToHome}
            className="w-full max-w-md mx-auto bg-white text-[#4f46e5] border-2 border-[#4f46e5] py-4 px-8 rounded-xl text-xl hover:bg-[rgba(108,92,231,0.1)] hover:border-[#433cca] transition-all duration-300 flex items-center justify-center"
          >
            <svg
              className="w-6 h-6 mr-2"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
}