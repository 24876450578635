import React, { useEffect, useState, useRef } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { Mic, MicOff } from 'lucide-react';

const VideoInterface = ({
  isAISpeaking,
  timer,
  onEndInterview,
  codeEditorState, // 'open' or 'closed'
  code,
  setCode,
  problemStatement,
  language,
  setLanguage,
  micMute,
  setMicMute,
}) => {
  const [cameraError, setCameraError] = useState(false);
  const [showModal, setShowModal] = useState(false); // For showing the input/output modal
  const [input, setInput] = useState(''); // User input for the code
  const [output, setOutput] = useState(''); // Output after running the code
  const monaco = useMonaco();
  const editorRef = useRef(null);
  const videoRef = useRef(null); // Ref for the single video element

  // Initialize editor
  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  // Setup camera on mount
  useEffect(() => {
    let stream;
    async function setupCamera() {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 1920 },
            height: { ideal: 1080 },
            facingMode: 'user',
          },
          audio: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
        setCameraError(false);
      } catch (err) {
        console.error('Error accessing camera:', err);
        setCameraError(true);
      }
    }

    setupCamera();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  /**
   * Formats the timer from seconds to MM:SS format.
   * @param {number} seconds
   * @returns {string}
   */
  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Function to run the code using the Judge0 API
  const runCode = async () => {
    const languageIdMap = {
      javascript: 102,
      python: 100,
      cpp: 52,
      java: 91,
    };

    // Function to encode text in Base64
    const encodeBase64 = (text) => {
      return btoa(unescape(encodeURIComponent(text)));
    };

    // Function to decode Base64 to text
    const decodeBase64 = (base64Text) => {
      return decodeURIComponent(escape(atob(base64Text)));
    };

    try {
      // Encode the source code and input in Base64 if necessary
      const encodedSourceCode = encodeBase64(code);
      const encodedInput = encodeBase64(input);

      const response = await fetch(
        'https://judge0-ce.p.rapidapi.com/submissions?base64_encoded=true&wait=true&fields=*',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-RapidAPI-Key': 'YOUR_RAPIDAPI_KEY_HERE', // Replace with your RapidAPI key
            'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
          },
          body: JSON.stringify({
            source_code: encodedSourceCode, // Base64 encoded code
            language_id: languageIdMap[language],
            stdin: encodedInput, // Base64 encoded input
          }),
        }
      );

      const data = await response.json();

      if (data.stdout || data.stderr) {
        const decodedOutput = decodeBase64(data.stdout || data.stderr || 'No output returned');
        setOutput(decodedOutput);
      } else {
        setOutput('Error: No output received.');
      }
    } catch (error) {
      console.error('Error running code:', error);
      setOutput('Error running the code.');
    }
  };

  // Render the camera or error message
  const renderCamera = () => {
    if (cameraError) {
      return (
        <div className="w-full h-full flex items-center justify-center bg-gray-200 p-4">
          <div className="text-center text-gray-800">
            <svg
              className="w-16 h-16 mx-auto mb-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
            <p className="text-lg font-medium">Camera access required</p>
            <p className="text-sm text-gray-600 mt-2">Please enable camera access to continue.</p>
          </div>
        </div>
      );
    } else {
      return <video ref={videoRef} className="w-full h-full object-cover rounded-xl" autoPlay playsInline muted />;
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-100 overflow-hidden">
      {/* Main container: If problemStatement is available, we show two columns */}
      {problemStatement ? (
        <div className="flex flex-col h-[calc(100%-64px)] md:flex-row">
          {/* Left column: Problem statement always on top */}
          <div className="w-full md:w-1/3 h-full flex flex-col bg-gray-200 border-r border-gray-300 overflow-y-auto">
            <div className="p-6 flex-shrink-0">
              <h2 className="text-xl font-semibold text-[--moredark-color] mb-4">Problem Statement</h2>
              <div className="text-gray-800 whitespace-pre-wrap">{problemStatement}</div>
            </div>

            {/* When the code editor is open, camera goes below the problem statement */}
            {codeEditorState === 'open' && (
              <div className="flex-grow p-6">
                <div className={`relative w-full h-48 rounded-xl overflow-hidden shadow-xl ${!isAISpeaking ? 'ring-2 ring-[--moredark-color]' : ''}`}>
                  {renderCamera()}
                  <div className="absolute bottom-2 left-2 bg-white bg-opacity-50 backdrop-blur-sm px-2 py-1 rounded-lg">
                    <span className="text-gray-800 text-xs font-medium">You</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Right column */}
          <div className="relative w-full md:w-2/3 h-full bg-gray-200">
            {/* Code Editor appears here when open */}
            <div
              className={`absolute inset-0 bg-gray-200 transition-transform duration-300 ease-in-out ${
                codeEditorState === 'open' ? 'translate-y-0' : 'translate-y-full'
              }`}
              onTransitionEnd={() => {
                // Optional: Handle any state changes after transition
              }}
            >
              {codeEditorState === 'open' && (
                <div className="h-full opacity-0 animate-fadeIn relative">
                  {/* Language Selector */}
                  <div className="absolute top-4 right-4 z-20">
                    <div className="relative">
                      <select
                        className="appearance-none bg-gray-300 text-gray-800 text-sm font-medium px-4 py-2 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-[--moredark-color] transition-all"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        <option value="cpp">C++</option>
                        <option value="python">Python</option>
                        <option value="java">Java</option>
                        <option value="javascript">JavaScript</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <Editor
                    height="100%"
                    language={language}
                    theme="vs-dark"
                    value={code}
                    onChange={setCode}
                    onMount={handleEditorDidMount}
                    options={{
                      minimap: { enabled: false },
                      fontSize: 14,
                      scrollBeyondLastLine: false,
                      wordWrap: 'on',
                      automaticLayout: true,
                    }}
                  />
                </div>
              )}
            </div>

            {/* If code editor is closed, show the camera here (right column) */}
            {codeEditorState === 'closed' && (
              <div className={`absolute inset-0 flex items-center justify-center ${!isAISpeaking ? 'ring-2 ring-[--moredark-color]' : ''}`}>
                {renderCamera()}
                <div className="absolute bottom-2 left-2 bg-white bg-opacity-50 backdrop-blur-sm px-2 py-1 rounded-lg">
                  <span className="text-gray-800 text-xs font-medium">You</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        // If no problem statement, default layout: full screen camera or editor
        <div className="flex flex-col h-[calc(100%-64px)]">
          {/* If no problem statement and editor closed: full screen camera */}
          {codeEditorState === 'closed' && (
            <div className={`relative w-full h-full ${!isAISpeaking ? 'ring-2 ring-[--moredark-color]' : ''}`}>
              {renderCamera()}
              <div className="absolute bottom-2 left-2 bg-white bg-opacity-50 backdrop-blur-sm px-2 py-1 rounded-lg">
                <span className="text-gray-800 text-xs font-medium">You</span>
              </div>
            </div>
          )}

          {/* If no problem statement and editor open: show editor full screen */}
          {codeEditorState === 'open' && (
            <div
              className={`relative w-full h-full bg-gray-200 transition-transform duration-300 ease-in-out ${
                codeEditorState === 'open' ? 'translate-y-0' : 'translate-y-full'
              }`}
              onTransitionEnd={() => {
                // Optional: Handle any state changes after transition
              }}
            >
              {codeEditorState === 'open' && (
                <div className="h-full opacity-0 animate-fadeIn relative">
                  {/* Language Selector */}
                  <div className="absolute top-4 right-4 z-20">
                    <div className="relative">
                      <select
                        className="appearance-none bg-gray-300 text-gray-800 text-sm font-medium px-4 py-2 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-[--moredark-color] transition-all"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        <option value="cpp">C++</option>
                        <option value="python">Python</option>
                        <option value="java">Java</option>
                        <option value="javascript">JavaScript</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="w-4 h-4 text-gray-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <Editor
                    height="100%"
                    language={language}
                    theme="vs-dark"
                    value={code}
                    onChange={setCode}
                    onMount={handleEditorDidMount}
                    options={{
                      minimap: { enabled: false },
                      fontSize: 14,
                      scrollBeyondLastLine: false,
                      wordWrap: 'on',
                      automaticLayout: true,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* Control Bar */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gray-300 bg-opacity-95 backdrop-blur-sm border-t border-gray-400">
        <div className="h-full max-w-screen-xl mx-auto px-6 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse"></div>
              <span className="text-gray-800 text-sm font-medium">{formatTimer(timer)}</span>
            </div>
            <span className="text-gray-600">|</span>
            <span className="text-gray-800 text-sm">Technical Interview</span>
          </div>

          <div className="flex items-center space-x-2">
            {/* Placeholder button - you can customize its functionality */}
            <button className="p-3 rounded-lg hover:bg-gray-400 text-gray-800 transition-colors">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
            </button>

            <button
              className={`p-3 rounded-lg ${micMute ? 'bg-red-600' : 'bg-gray-400'} hover:bg-opacity-80 text-gray-800 transition-colors`}
              onClick={() => setMicMute(!micMute)}
              aria-label={micMute ? 'Unmute Microphone' : 'Mute Microphone'}
            >
              {micMute ? <MicOff size={24} /> : <Mic size={24} />}
            </button>

            <button
              className="px-4 py-2 rounded-lg bg-[--moredark-color] hover:bg-[--moredark-color] text-white     
                transition-colors duration-200 flex items-center space-x-2"
              onClick={() => setShowModal(true)}
            >
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>Run Code</span>
            </button>
          </div>
        </div>
      </div>

      {/* Modal for input/output */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-200/80 backdrop-blur-sm flex justify-center items-center z-50">
          <div className="bg-gray-100 rounded-xl w-[800px] shadow-2xl border border-gray-300 overflow-hidden">
            {/* Modal Header */}
            <div className="px-6 py-4 border-b border-gray-300 flex justify-between items-center">
              <div className="flex items-center space-x-3">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 rounded-full bg-red-500"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <h2 className="text-lg font-semibold text-gray-800">Code Execution</h2>
              </div>
              <button
                onClick={() => setShowModal(false)}
                className="text-gray-600 hover:text-gray-800 transition-colors"
                aria-label="Close Modal"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Modal Content */}
            <div className="p-6">
              <div className="grid grid-cols-2 gap-6">
                {/* Input Section */}
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium text-gray-800">Input</label>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => setInput('')}
                        className="text-xs text-gray-600 hover:text-gray-800 transition-colors"
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                  <div className="relative">
                    <textarea
                      className="w-full h-[300px] bg-gray-100 text-gray-800 rounded-lg p-4 font-mono text-sm resize-none    
                          border border-gray-300 focus:border-[--moredark-color] focus:ring-1 focus:ring-[--moredark-color]   
                          transition-all duration-200 ease-in-out"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Enter your input here..."
                      spellCheck="false"
                    />
                    <div className="absolute bottom-3 right-3 text-xs text-gray-500">
                      {input.length} characters
                    </div>
                  </div>
                </div>

                {/* Output Section */}
                <div className="space-y-3">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium text-gray-800">Output</label>
                    <div className="flex items-center space-x-2">
                      <button
                        onClick={() => setOutput('')}
                        className="text-xs text-gray-600 hover:text-gray-800 transition-colors"
                      >
                        Clear
                      </button>
                      <button
                        onClick={() => navigator.clipboard.writeText(output)}
                        className="text-xs text-gray-600 hover:text-gray-800 transition-colors"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-full h-[300px] bg-gray-100 text-gray-800 rounded-lg p-4 font-mono text-sm    
                            border border-gray-300 overflow-auto">
                      {output ? (
                        <pre className="whitespace-pre-wrap">{output}</pre>
                      ) : (
                        <div className="text-gray-500 italic">Output will appear here...</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Action Buttons */}
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 rounded-lg text-sm font-medium text-gray-600 hover:text-gray-800    
                     bg-gray-300 hover:bg-gray-200 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={runCode}
                  className="px-4 py-2 rounded-lg text-sm font-medium text-white    
                     bg-[--dark-color] hover:bg-[--moredark-color] transition-colors duration-200    
                     flex items-center space-x-2"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Run Code</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoInterface;
