// src/AIInterviewPlayer.js
import React, { useEffect, useRef, useState } from "react";
import {
    FetchAIAudios,
    fetchProctoringResults,
    getVideoChunks,
    getVideoUrl
} from "../../services/api";
import {
    base64ToUint8Array,
    createSilenceBuffer,
    decodeAudio,
    encodeWAV,
    mergeAudioBuffers,
    parseTimeToSeconds
} from "../../utils/audioUtils"; // Import the encodeWAV function
import { getBlobUrl } from "../../services/api2";

const AIInterviewPlayer = ({ interviewId, email }) => {
    // const [email] = useState("kartik1014@gmail.com");
    // const [interviewId] = useState("46383c66-3e33-4997-b21c-c9b444c27526");
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [mergedAudioBuffer, setMergedAudioBuffer] = useState(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isAudioReady, setIsAudioReady] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [proctoringResults, setProctoringResults] = useState([]);
    const [currentProctoringResult, setCurrentProctoringResult] = useState(null);
    const [proctoringMarkers, setProctoringMarkers] = useState([]);
    const [baseTime, setBaseTime] = useState(null)
    const [volume, setVolume] = useState(100);
    // Refs for audio nodes and video element
    const videoRef = useRef(null);
    const mediaElementSourceRef = useRef(null); // Single MediaElementAudioSourceNode
    const aiAudioSourceRef = useRef(null);
    const gainNodeRef = useRef(null);
    const animationFrameRef = useRef(null);
    const markerStyles = `
  .marker-tooltip {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    background-color: rgba(17, 24, 39, 0.95);
    color: white;
    padding: 12px;
    border-radius: 8px;
    font-size: 13px;
    z-index: 50;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    transition: all 0.2s ease;
    opacity: 0;
    pointer-events: none;
    min-width: 200px;
  }

  .marker-container:hover .marker-tooltip {
    visibility: visible;
    transform: translateX(-50%) translateY(-10px);
    opacity: 1;
  }

  .marker-container:hover .marker-dot {
    transform: scale(1.5) translateX(-30%) translateY(-30%);
    box-shadow: 0 0 12px rgba(99, 102, 241, 0.5);
  }

  .marker-dot {
    transition: all 0.2s ease;
  }
`;




    const handleVolumeChange = (e) => {
        const newVolume = parseInt(e.target.value);
        setVolume(newVolume);
        if (gainNodeRef.current) {
            gainNodeRef.current.gain.value = newVolume / 100;
        }
    };
    // Initialize AudioContext on mount
    useEffect(() => {
        const context = new (window.AudioContext || window.webkitAudioContext)();
        setAudioContext(context);

        return () => {
            if (context) {
                context.close();
            }
        };
    }, []);
    useEffect(() => {
        const fetchProctoringImages = async () => {
            try {
                let response = await fetchProctoringResults(interviewId, email);
                if (!response.error) {

                    console.log(response.images)
                    setProctoringResults(response.images);
                }

            }
            catch (err) {
                setError("Failed to fetch proctoring results");
                console.error(err);
            }

        }
        // fetchProctoringImages()
    }, [interviewId, email])
    useEffect(() => {
        if (proctoringResults.length > 0 && duration > 0 && baseTime != null) {
            // Convert timestamp to seconds since interview start
            const markers = proctoringResults.map(result => {
                // Assuming timeStamp is in format "HH:MM:SS.mmm"

                const totalSeconds = parseTimeToSeconds(result.timeStamp);

                return {
                    time: parseInt(totalSeconds - baseTime),
                    result: result
                };
            }).filter(marker => marker.time <= duration);

            setProctoringMarkers(markers);
        }
    }, [proctoringResults, duration, baseTime]);

    // Update current proctoring result based on current time
    useEffect(() => {
        if (proctoringMarkers.length > 0) {
            const activeMarker = proctoringMarkers.find(marker =>
                Math.abs(marker.time - currentTime) < 0.5 // Within 0.5 seconds
            );

            setCurrentProctoringResult(activeMarker ? activeMarker.result : null);
        }
    }, [currentTime, proctoringMarkers]);
    const renderProctoringResultDetails = () => {
        if (!currentProctoringResult) return null;

        const { image, result } = currentProctoringResult;
        const {
            isPersonHoldingMobile,
            isPersonWatchingInCamera,
            isPersonWearingMask,
            numberOfPerson
        } = result;

        return (
            <>
            </>
        );
    };
    // Fetch AI audio when AudioContext is ready
    useEffect(() => {
        if (audioContext) {
            fetchAndProcessChunks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioContext]);

    // Fetch video URL on mount
    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                // Fetch the original video URL
                const originalVideoUrl = await getBlobUrl(interviewId, email);
                

                // Fetch proxied video chunks
                const response = await getVideoChunks(originalVideoUrl.url);

                // Set the proxied video URL
                setVideoUrl(response.proxiedVideoUrl);
            } catch (err) {
                setError("Failed to fetch video URL");
                console.error(err);
            }
        };

        fetchVideoUrl();


    }, [interviewId, email]);

    // Utility Functions

    /**
     * Fetches, decodes, and merges AI audio chunks into a single AudioBuffer.
     */
    const fetchAndProcessChunks = async () => {
        setIsFetching(true);
        setError(null);
        try {
            const response = await FetchAIAudios(email, interviewId);
            console.log(response);
            const { createdAt, data, endAt } = response;
            
            if (!createdAt || !data) {
                throw new Error("Invalid response format");
            }

            const baseTimeSec = parseTimeToSeconds(createdAt);
            const endTimeSec = parseTimeToSeconds(endAt)
            console.log(baseTimeSec, endTimeSec)
            setBaseTime(baseTimeSec)
            if (baseTimeSec && endTimeSec) {
                console.log(parseInt(endTimeSec - baseTimeSec))
                setDuration(parseInt(endTimeSec - baseTimeSec))

            }
            const processedGroups = data
                .map((groupData) => {
                    const { startTime, chunks } = groupData;

                    if (!startTime || !chunks) return null;

                    const groupStartTimeSec = parseTimeToSeconds(startTime) - baseTimeSec;

                    if (groupStartTimeSec < 0) return null;

                    return {
                        startTimeSec: groupStartTimeSec,
                        chunks: chunks.map((chunk) => ({
                            audioChunk: chunk,
                        })),
                    };
                })
                .filter(Boolean)
                .sort((a, b) => a.startTimeSec - b.startTimeSec);

            // Decode all chunks
            const decodedGroups = await Promise.all(
                processedGroups.map(async (group) => {
                    const decodedChunks = await Promise.all(
                        group.chunks.map(async (chunk) => {
                            const uintArray = base64ToUint8Array(chunk.audioChunk);
                            const decodedAudio = await decodeAudio(audioContext, uintArray);

                            return decodedAudio;
                        })
                    );
                    return {
                        ...group,
                        decodedChunks,
                    };
                })
            );

            // Merge all decoded chunks with silence between groups
            let mergedBuffers = [];
            let previousEndTime = 0;

            for (const group of decodedGroups) {
                // Calculate silence duration between previous group and current group
                const silenceDuration = group.startTimeSec - previousEndTime;
                if (silenceDuration > 0) {
                    const silenceBuffer = createSilenceBuffer(silenceDuration);
                    mergedBuffers.push(silenceBuffer);
                    previousEndTime += silenceDuration;
                }

                // Merge all chunks in the current group sequentially
                for (const decodedChunk of group.decodedChunks) {
                    mergedBuffers.push(decodedChunk);
                    previousEndTime += decodedChunk.duration;
                }
            }

            // Create a single merged AudioBuffer
            const mergedAudio = mergeAudioBuffers(mergedBuffers);
            setMergedAudioBuffer(mergedAudio);
            setIsAudioReady(true);
        } catch (err) {
            setError(err.message || "Failed to fetch audio chunks");
            console.error("Fetch error:", err);
        } finally {
            setIsFetching(false);
        }
    };

    /**
     * Handles play/pause toggle.
     * Sets up audio nodes and synchronizes AI audio with video playback.
     */
    const handlePlayPause = async () => {
        if (!isPlaying) {
            // Check if audioContext and mergedAudioBuffer are ready
            if (!audioContext || !mergedAudioBuffer || !videoUrl) {
                console.warn("AudioContext, audio data, or video URL not ready yet.");
                return;
            }

            const videoElement = videoRef.current;
            if (!videoElement) {
                console.warn("Video element not ready.");
                return;
            }

            try {
                if (audioContext.state === "suspended") {
                    await audioContext.resume();
                }

                // Create MediaElementAudioSourceNode only once
                if (!mediaElementSourceRef.current) {
                    mediaElementSourceRef.current = audioContext.createMediaElementSource(videoElement);
                }

                // Create GainNode if not already created
                if (!gainNodeRef.current) {
                    gainNodeRef.current = audioContext.createGain();
                    gainNodeRef.current.gain.value = 1; // Adjust as necessary
                    // Connect media source to gain node
                    mediaElementSourceRef.current.connect(gainNodeRef.current);
                    // Connect gain node to destination
                    gainNodeRef.current.connect(audioContext.destination);
                }

                // Create and connect AI Audio Buffer Source
                aiAudioSourceRef.current = audioContext.createBufferSource();
                aiAudioSourceRef.current.buffer = mergedAudioBuffer;
                aiAudioSourceRef.current.loop = false;

                // Connect AI audio to gain node
                aiAudioSourceRef.current.connect(gainNodeRef.current);

                // Start AI audio from the current video time
                const currentTime = videoElement.currentTime;

                aiAudioSourceRef.current.start(0, currentTime);

                // Play the video
                await videoElement.play();

                // Start updating the current time
                animationFrameRef.current = requestAnimationFrame(updateCurrentTime);

                setIsPlaying(true);
            } catch (err) {
                setError(`Playback failed: ${err.message}`);
                console.error("Playback error:", err);
            }
        } else {
            // Pause playback
            const videoElement = videoRef.current;
            if (videoElement) {
                videoElement.pause();
            }

            // Stop and disconnect AI audio source
            if (aiAudioSourceRef.current) {
                try {
                    aiAudioSourceRef.current.stop();
                    aiAudioSourceRef.current.disconnect();
                } catch (e) {
                    console.warn("AI Audio Source already stopped.");
                }
                aiAudioSourceRef.current = null;
            }

            // Cancel the animation frame
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }

            setIsPlaying(false);
        }
    };

    /**
     * Updates the current time state based on video playback.
     */
    const updateCurrentTime = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            setCurrentTime(videoElement.currentTime);
            animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
        }
    };

    /**
     * Handles the seeked event to synchronize AI audio with video.
     */
    const handleSeeked = () => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        setCurrentTime(videoElement.currentTime);

        if (isPlaying) {
            // Stop current AI audio
            if (aiAudioSourceRef.current) {
                try {
                    aiAudioSourceRef.current.stop();
                    aiAudioSourceRef.current.disconnect();
                } catch (e) {
                    console.warn("AI Audio Source already stopped.");
                }
                aiAudioSourceRef.current = null;
            }

            // Create a new AI Audio Buffer Source
            if (audioContext && mergedAudioBuffer) {
                aiAudioSourceRef.current = audioContext.createBufferSource();
                aiAudioSourceRef.current.buffer = mergedAudioBuffer;
                aiAudioSourceRef.current.loop = false;

                // Connect AI audio to gain node
                aiAudioSourceRef.current.connect(gainNodeRef.current);

                // Start AI audio from the new video time
                const currentTime = videoElement.currentTime;
                aiAudioSourceRef.current.start(0, currentTime);
            }
        }
    };

    /**
     * Handles manual seeking via the seek bar.
     * @param {Event} e - The input event from the range input.
     */
    const handleSeek = (e) => {
        const seekTime = parseFloat(e.target.value);
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.currentTime = seekTime;
            setCurrentTime(seekTime);
        }
    };

    /**
     * Skips the video forward by 10 seconds.
     */
    const skipForward = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.currentTime = Math.min(videoElement.currentTime + 10, duration);
            setCurrentTime(videoElement.currentTime);
        }
    };

    /**
     * Skips the video backward by 10 seconds.
     */
    const skipBackward = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.currentTime = Math.max(videoElement.currentTime - 10, 0);
            setCurrentTime(videoElement.currentTime);
        }
    };

    /**
     * Handles loadedmetadata event to get video duration.
     */
    const handleLoadedMetadata = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            // setDuration(videoElement.duration);
            setIsVideoLoaded(true);
        }
    };

    /**
     * Handles canplaythrough event to indicate that the video can be played completely without buffering.
     */
    const handleCanPlayThrough = () => {
        setIsVideoLoaded(true);
    };

    /**
     * Clean up audio nodes on component unmount.
     */
    useEffect(() => {
        return () => {
            if (aiAudioSourceRef.current) {
                try {
                    aiAudioSourceRef.current.stop();
                    aiAudioSourceRef.current.disconnect();
                } catch (e) {
                    console.warn("AI Audio Source already stopped.");
                }
                aiAudioSourceRef.current = null;
            }
            if (mediaElementSourceRef.current) {
                mediaElementSourceRef.current.disconnect();
                mediaElementSourceRef.current = null;
            }
            if (gainNodeRef.current) {
                gainNodeRef.current.disconnect();
                gainNodeRef.current = null;
            }
            if (audioContext) {
                // audioContext.close();
            }
            // Cancel the animation frame
            cancelAnimationFrame(animationFrameRef.current);
        };
    }, [audioContext]);

    /**
     * Determine if both video and AI audio are ready to be played.
     */
    const isReadyToPlay = isVideoLoaded && isAudioReady;

    /**
     * Format time in seconds to "MM:SS" format.
     * @param {number} time - Time in seconds.
     * @returns {string} - Formatted time string.
     */
    const formatTime = (time) => {
        if (isNaN(time)) return "00:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutesStr}:${secondsStr}`;
    };

    // Modified return statement with improved UI
    return (
        <div className=" bg-white">
            <style>{markerStyles}</style>

            {/* Main Container */}
            <div className="max-w-[1280px] mx-auto p-4">
                {/* Video Section */}
                <div className="flex flex-col items-center">
                    {/* Video Container with 60% width on medium screens and above */}
                    <div className="relative group w-full md:w-3/5 mx-auto">
                        {/* Video Container */}
                        <div className="relative aspect-video bg-gradient-to-b from-gray-900 to-black rounded-xl overflow-hidden shadow-2xl">
                            <video
                                ref={videoRef}
                                src={videoUrl}
                                className="w-full h-full object-contain"
                                crossOrigin="anonymous"
                                onSeeked={handleSeeked}
                                onLoadedMetadata={handleLoadedMetadata}
                                onCanPlayThrough={handleCanPlayThrough}
                                preload="auto"
                            />

                            {/* Floating Status Indicator */}
                            <div className="absolute top-4 right-4 z-10">
                                {currentProctoringResult && (
                                    <div
                                        className={`px-4 py-2 rounded-full flex items-center gap-2 text-sm font-medium ${currentProctoringResult.isPersonHoldingMobile ||
                                            !currentProctoringResult.isPersonWatchingInCamera
                                            ? 'bg-red-500/80 text-white'
                                            : 'bg-green-500/80 text-white'
                                            }`}
                                    >
                                        <div className="w-2 h-2 rounded-full bg-white animate-pulse" />
                                        {currentProctoringResult.isPersonHoldingMobile
                                            ? 'Violation Detected'
                                            : 'All Clear'}
                                    </div>
                                )}
                            </div>

                            {/* Video Controls Overlay */}
                            <div className="absolute bottom-0 left-0 right-0 w-full bg-gradient-to-t from-black/90 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300">
                                {/* Progress Bar Container */}
                                <div className="absolute -top-3 left-0 right-0 h-3 cursor-pointer group">
                                    <div className="absolute bottom-0 left-0 right-0 h-[3px] group-hover:h-[6px] transition-all duration-200">
                                        {/* Background Track */}
                                        <div className="absolute w-full h-full bg-white/30" />

                                        {/* Buffered Track */}
                                        <div
                                            className="absolute h-full bg-white/40"
                                            style={{ width: '100%' }}
                                        />

                                        {/* Played Progress */}
                                        <div
                                            className="relative h-full bg-[#ff0000]"
                                            style={{ width: `${(currentTime / duration) * 100}%` }}
                                        >
                                            {/* Playhead */}
                                            <div className="absolute -right-[7px] top-1/2 -translate-y-1/2 w-[14px] h-[14px] bg-[#ff0000] rounded-full scale-0 group-hover:scale-100 transition-transform duration-200" />
                                        </div>

                                        {/* Proctoring Markers */}
                                        {proctoringMarkers.map((marker, index) => (
                                            <div
                                                key={index}
                                                className="marker-container absolute top-1/2 cursor-pointer"
                                                style={{
                                                    left: `${(marker.time / duration) * 100}%`,
                                                }}
                                            >
                                                {/* Marker Dot */}
                                                <div
                                                    className={`marker-dot w-3 h-3 rounded-full transform -translate-x-1/2 -translate-y-1/2 
            bg-yellow-400 ${marker.result.isPersonHoldingMobile ||
                                                            !marker.result.isPersonWatchingInCamera ||
                                                            marker.result.numberOfPerson > 1
                                                            ? 'animate-pulse'
                                                            : ''
                                                        }`}
                                                />

                                                {/* Analysis Tooltip */}
                                                <div className="marker-tooltip">
                                                    <div className="flex flex-col gap-2">
                                                        {/* Time */}
                                                        <div className="text-xs text-gray-400 mb-1">
                                                            Time: {formatTime(marker.time)}
                                                        </div>

                                                        {/* Analysis Results */}
                                                        <div className="space-y-2">
                                                            {/* Mobile Detection */}
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Mobile Detection:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.isPersonHoldingMobile
                                                                    ? 'bg-red-500/20 text-red-400'
                                                                    : 'bg-green-500/20 text-green-400'
                                                                    }`}>
                                                                    {marker.result.isPersonHoldingMobile ? 'Detected' : 'Clear'}
                                                                </span>
                                                            </div>

                                                            {/* Camera Focus */}
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Camera Focus:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.isPersonWatchingInCamera
                                                                    ? 'bg-green-500/20 text-green-400'
                                                                    : 'bg-red-500/20 text-red-400'
                                                                    }`}>
                                                                    {marker.result.isPersonWatchingInCamera ? 'Focused' : 'Not Focused'}
                                                                </span>
                                                            </div>

                                                            {/* Person Count */}
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Person Count:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.numberOfPerson > 1
                                                                    ? 'bg-red-500/20 text-red-400'
                                                                    : 'bg-green-500/20 text-green-400'
                                                                    }`}>
                                                                    {marker.result.numberOfPerson} {marker.result.numberOfPerson > 1 ? 'People' : 'Person'}
                                                                </span>
                                                            </div>

                                                            {/* Mask Detection */}
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Mask Status:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.isPersonWearingMask
                                                                    ? 'bg-green-500/20 text-green-400'
                                                                    : 'bg-red-500/20 text-red-400'
                                                                    }`}>
                                                                    {marker.result.isPersonWearingMask ? 'Wearing' : 'Not Wearing'}
                                                                </span>
                                                            </div>
                                                        </div>

                                                        {/* Overall Status */}
                                                        <div className="mt-2 pt-2 border-t border-gray-700">
                                                            <div className={`text-center text-sm font-medium ${marker.result.isPersonHoldingMobile ||
                                                                !marker.result.isPersonWatchingInCamera ||
                                                                marker.result.numberOfPerson > 1
                                                                ? 'text-red-400'
                                                                : 'text-green-400'
                                                                }`}>
                                                                {marker.result.isPersonHoldingMobile ||
                                                                    !marker.result.isPersonWatchingInCamera ||
                                                                    marker.result.numberOfPerson > 1
                                                                    ? '⚠️ Violations Detected'
                                                                    : '✅ All Clear'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Range Input */}
                                    {duration != null && (
                                        <input
                                            type="range"
                                            min="0"
                                            max={duration}
                                            value={currentTime}
                                            onChange={handleSeek}
                                            className="absolute bottom-0 left-0 w-full opacity-0 cursor-pointer"
                                            style={{ height: '24px', margin: 0 }}
                                        />
                                    )}
                                </div>

                                {/* Controls Container */}
                                <div className="relative px-6 py-4">
                                    <div className="flex items-center justify-between">
                                        {/* Left Controls */}
                                        <div className="flex items-center space-x-4">
                                            {/* Play/Pause Button */}
                                            <button
                                                onClick={handlePlayPause}
                                                className="text-white hover:text-white/80 transition-colors focus:outline-none"
                                                disabled={!isReadyToPlay || isFetching}
                                            >
                                                {isPlaying ? (
                                                    <svg
                                                        className="w-8 h-8"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 5v14h2V5H8zm6 0v14h2V5h-2z"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        className="w-8 h-8"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M8 5.14v14l11-7-11-7z" />
                                                    </svg>
                                                )}
                                            </button>

                                            {/* Skip Buttons */}
                                            <div className="flex items-center gap-2">
                                                <button
                                                    onClick={skipBackward}
                                                    className="text-white hover:text-white/80 transition-colors focus:outline-none"
                                                >
                                                    <svg
                                                        className="w-6 h-6"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M11.5 12l8.5 6V6l-8.5" />
                                                    </svg>
                                                </button>
                                                <button
                                                    onClick={skipForward}
                                                    className="text-white hover:text-white/80 transition-colors focus:outline-none"
                                                >
                                                    <svg
                                                        className="w-6 h-6"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M4 18l8.5-6L4 6v12zm" />
                                                    </svg>
                                                </button>
                                            </div>

                                            {/* Volume Control */}
                                            <div className="flex items-center group">
                                                <button className="text-white hover:text-white/80 transition-colors focus:outline-none">
                                                    <svg
                                                        className="w-6 h-6"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02z" />
                                                    </svg>
                                                </button>
                                                <div className="w-0 overflow-hidden group-hover:w-24 transition-all duration-300">
                                                    <input
                                                        type="range"
                                                        className="w-24 h-1 ml-2 appearance-none bg-white/30 rounded-full [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-3 [&::-webkit-slider-thumb]:h-3 [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-white"
                                                        min="0"
                                                        max="100"
                                                        value={volume}
                                                        onChange={handleVolumeChange}
                                                    />
                                                </div>
                                            </div>

                                            {/* Time Display */}
                                            <div className="flex items-center text-sm text-white">
                                                <span className="font-medium">{formatTime(currentTime)}</span>
                                                <span className="mx-1 opacity-60">/</span>
                                                <span className="opacity-60">{formatTime(duration)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Loading Overlay */}
                        {(isFetching || !isReadyToPlay) && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black/60 rounded-xl">
                                <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent" />
                            </div>
                        )}
                    </div>

                    {/* Error Message */}
                    {error && (
                        <div className="mt-4 p-4 bg-red-900/50 text-red-100 rounded-xl border border-red-500">
                            <div className="flex items-center space-x-2">
                                <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                </svg>
                                <div>
                                    <p className="font-medium">Error occurred</p>
                                    <p className="text-sm opacity-90">{error}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Proctoring Details Panel */}
                    {renderProctoringResultDetails()}
                </div>
            </div>
        </div>
    );

};

export default AIInterviewPlayer;
