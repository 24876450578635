import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMicrophone, FaClock, FaLightbulb } from 'react-icons/fa';


const StartPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        interviewId: ''
    });
    const [errors, setErrors] = useState({
        email: '',
        interviewId: ''
    });
    const navigate = useNavigate();

    const validateEmail = (email) => {
        return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    };

    const validateForm = () => {
        const newErrors = {
            email: '',
            interviewId: ''
        };
        let isValid = true;

        if (!formData.email) {
            newErrors.email = 'Email is required';
            isValid = false;
        } else if (!validateEmail(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }

        if (!formData.interviewId) {
            newErrors.interviewId = 'Interview ID is required';
            isValid = false;
        } else if (formData.interviewId.length < 3) {
            newErrors.interviewId = 'Interview ID must be at least 3 characters';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleStartInterview = () => {
        if (validateForm()) {
            localStorage.setItem('email', formData.email);
            navigate(`/interview?id=${formData.interviewId}`);
        }
    };

    return (
        <div className="min-h-screen bg-[#f5f5f5]">
            {/* Header */}
            <div className="bg-light-gray text-black py-16">
                <div className="container mx-auto px-4 text-center">
                    <img
                        src="https://easecruit.com/images/Logo.png"
                        alt=""
                        className="h-16 mx-auto mb-8"
                    />
                    <h1 className="text-3xl font-bold mb-4">
                        AI-Powered Technical Interview
                    </h1>
                    <p className="text-lg max-w-2xl mx-auto">
                        Experience a cutting-edge technical interview conducted by our advanced AI system.
                    </p>
                </div>
            </div>

            <div className="container mx-auto px-4 py-12">
                <div className="max-w-6xl mx-auto grid lg:grid-cols-2 gap-8">
                    {/* Form Section */}
                    <div className="bg-white p-8 rounded-lg">
                        <h2 className="text-2xl font-bold text-[#4f46e5] mb-6">
                            Start Your Interview
                        </h2>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Interview ID
                                </label>
                                <input
                                    name="interviewId"
                                    type="text"
                                    value={formData.interviewId}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-[#f5f5f5] rounded-lg"
                                    placeholder="Enter Interview ID"
                                />
                                {errors.interviewId && (
                                    <p className="mt-1 text-sm text-red-600">{errors.interviewId}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">
                                    Email Address
                                </label>
                                <input
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-2 bg-[#f5f5f5] rounded-lg"
                                    placeholder="your@email.com"
                                />
                                {errors.email && (
                                    <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                                )}
                            </div>

                            <button
                                onClick={handleStartInterview}
                                className=" bg-[#4f46e5] text-white py-2 px-5 rounded-full mt-4"
                            >
                                Start Interview
                            </button>
                        </div>
                    </div>

                    {/* Guidelines Section */}
                    <div className="bg-white p-8 rounded-lg">
                        <h3 className="text-2xl font-bold text-[#4f46e5] mb-6">
                            Interview Guidelines
                        </h3>
                        <div className="space-y-6">
                            <div className="flex gap-4">
                                <FaMicrophone className="text-[#4f46e5] text-xl mt-1" />
                                <div>
                                    <h4 className="font-medium mb-1">Clear Communication</h4>
                                    <p className="text-gray-600">Speak clearly and maintain a consistent pace.</p>
                                </div>
                            </div>

                            <div className="flex gap-4">
                                <FaClock className="text-[#4f46e5] text-xl mt-1" />
                                <div>
                                    <h4 className="font-medium mb-1">Time Management</h4>
                                    <p className="text-gray-600">The interview typically takes 30-45 minutes.</p>
                                </div>
                            </div>

                            <div className="flex gap-4">
                                <FaLightbulb className="text-[#4f46e5] text-xl mt-1" />
                                <div>
                                    <h4 className="font-medium mb-1">Best Practices</h4>
                                    <ul className="text-gray-600 list-disc list-inside">
                                        <li>Use headphones for better audio quality</li>
                                        <li>Find a quiet environment</li>
                                        <li>Keep your responses focused and concise</li>
                                        <li>Take time to think when needed</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartPage;  