// src/components/InterviewInterface/InterviewInterface.js

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AudioHandler from '../../handlers/AudioHandler';
import WebSocketService from '../../services/WebSocketService';
import { base64ToUint8Array, bufferToPCM16 } from '../../utils/base64';
import startInterviewMp3 from '../../assets/start-interview.mp3';
import submitCodeAudio from '../../assets/submit_code.mp3';
import InterviewStartPage from '../StartPage/StartPage';
import RecordRTC from 'recordrtc';
import VideoInterface from './CodingInterviewScreen'; // Assuming InterviewScreen.js exports VideoInterface
import { data } from 'autoprefixer';
import { sendPreRecordedAudioToWebSocket, SendTextMessageToWebSocket } from './InterviewUtils';
import { AskHintInCode, FunctionCall, GenerateTestCases } from '../../services/api';
import CodingInterviewStartPage from '../StartPage/CodingInterviewStartPage';
import jeffAudio from '../../assets/jeffAudio.mp3'
import { runCode } from '../../utils/CodingInterviewUtils';
function CodingInterviewInterface() {
    const [isAIPlaying, setIsAIPlaying] = useState(false);
    const socketRef = useRef(null);
    const audioHandlerRef = useRef(null);
    const responseDoneReceivedRef = useRef(false);
    const [email, setEmail] = useState('kartik150704@gmail.com');
    const [aiResponse, setAIResponse] = useState('');
    const [userResponse, setUserResponse] = useState('');
    const jeffAudioRef = useRef(null);
    const [systemPrompt, setSystemPrompt] = useState(`You are an AI interviewer developed by Easecruit conducting a coding interview for Easecruit, keep the tone to be frank like an real interviewer, an AI-driven recruitment platform. The interview involves a coding question, a code editor for implementation, and Jeff as your assistant to provide hints if needed. Follow the structured flow below, ensuring continuity even if there are interruptions. If asked about the LLM, state that it is created by Easecruit’s team and provide no additional details.

Interview Flow:
Question Explanation:
Introduce yourself and explain the process briefly. Present an easy-level DSA question (binary search), explaining it clearly with examples if needed. Confirm the candidate understands the question.

Approach Discussion:
Ask the candidate to describe their approach:

If the approach is good, ask them to analyze its time complexity. Correct any errors in their analysis before proceeding.
If the approach is flawed, don't explain why and move to the next question.
Code Writing:
Once the approach is approved, say: "I am opening the code editor. Please write your solution."

Providing Assistance:
If the person is askig for the hint then tell him that hints are not allowed in this interview

Code Submission:
When the candidate completes their code and is ready to submit, confirm: "Your code has been submitted successfully. This concludes the interview."

Handling Interruptions:
If there's a disruption, resume the interview from where it was left off (e.g., approach discussion, code writing, etc.) without repeating completed steps.

Voice and Language:
Use English with Indian accent as language and if candidate shifts to another language then shift to that language.

LLM Attribution:
If asked about the LLM, state: "This LLM is created by the Easecruit Team." Do not reveal further details.
`);
    const [timer, setTimer] = useState(300);
    const [interviewId, setInterviewId] = useState('2e88f3eb-74d0-44d9-b60d-527de1da5cfb')
    const [isConnected, setIsConnected] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [screen, setScreen] = useState("starting");
    const [codeEditorState, setCodeEditorState] = useState('closed');
    const [lastEventId, setLastEventId] = useState(null)
    const [code, setCode] = useState("/* Write your code here */");
    const [hintedCode, setHintedCode] = useState('');
    const [problemStatement, setProblemStatement] = useState('kfjf');
    const [language, setLanguage] = useState('python');
    const [micMute, setMicMute] = useState(false)
    const problemStatementRef = useRef(problemStatement);
    const [testCases, setTestCases] = useState({});
    const audioRecorder = useRef(null);

    const navigate = useNavigate(); // Initialize useNavigate
    const location = useLocation()


    const codeRef = useRef(code);
    const testCasesRef = useRef(testCases);
    useEffect(() => {
        codeRef.current = code;
    }, [code]);

    useEffect(() => {
        testCasesRef.current = testCases;
    }, [testCases]);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const interviewId = searchParams.get('id');
        const email = localStorage.getItem('email');
        if (interviewId && email) {

            // setInterviewId(interviewId);
            // setEmail(email);
        }
    }, [location]);

    useEffect(() => {
        if (interviewId) {

            let response = fetch(`${process.env.REACT_APP_PORT}/interview/interviews/${interviewId}/credentials`);
            response.then((res) => res.json()).then((data) => {
                console.log(data);
                if (data.success) {
                    // setSystemPrompt(data.credentials.system_prompt);
                    setTimer(data.credentials.minutes * 120);
                }
            })
        }



    }, [interviewId])
    /**
     * Ends the interview by performing cleanup and navigating to the Thank You screen.
     */
    const EndInterview = () => {
        console.log('Ending interview...');

        // Stop the timer
        setTimer(0);

        // Close WebSocket connection
        if (socketRef.current) {
            socketRef.current.close();
            socketRef.current = null;
        }

        // Stop recording
        stopRecording();

        // Close AudioHandler
        // if (audioHandlerRef.current) {
        //     audioHandlerRef.current.close();
        //     audioHandlerRef.current = null;
        // }

        // Navigate to Thank You screen
        navigate('/thankyou');
    };

    /**
     * Starts audio recording.
     */
    const startRecording = async () => {
        if (isRecording) return;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioRecorder.current = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/wav',
                recorderType: RecordRTC.StereoAudioRecorder,
                numberOfAudioChannels: 1,
                timeSlice: 500,
                desiredSampRate: 24000,
                ondataavailable: (blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64data = reader.result.split(',')[1];
                        sendAudioChunk(base64data);
                    };
                    reader.readAsDataURL(blob);
                },
            });

            audioRecorder.current.startRecording();
            setIsRecording(true);
            console.log('Recording started');
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    /**
     * Stops audio recording.
     */
    const stopRecording = () => {
        if (!isRecording || !audioRecorder.current) return;

        audioRecorder.current.stopRecording(() => {
            audioRecorder.current = null;
            setIsRecording(false);
            console.log('Recording stopped');
        });
    };

    useEffect(() => {
        // Initialize AudioHandler
        audioHandlerRef.current = new AudioHandler();
        audioHandlerRef.current
            .initialize()
            .then(() => {
                console.log('AudioHandler initialized');
            })
            .catch((error) => {
                console.error('Error initializing AudioHandler:', error);
            });

        // Initialize WebSocketService
        socketRef.current = new WebSocketService(
            `wss://wsinterview.easecruit.com`, // WebSocket URL
            processMessage,
            onSocketOpen,
            onSocketClose,
            onSocketError
        );

        // Handle AudioContext suspension on user interaction
        const resumeAudioContext = () => {
            if (
                audioHandlerRef.current.context &&
                audioHandlerRef.current.context.state === 'suspended'
            ) {
                audioHandlerRef.current.context.resume();
            }
        };

        window.addEventListener('click', resumeAudioContext);
        window.addEventListener('keydown', resumeAudioContext);

        return () => {
            // Clean up on unmount
            if (socketRef.current) {
                socketRef.current.close();
            }
            if (audioHandlerRef.current) {
                audioHandlerRef.current.close();
            }
            window.removeEventListener('click', resumeAudioContext);
            window.removeEventListener('keydown', resumeAudioContext);
        };
    }, []);

    /**
     * WebSocket onOpen handler.
     */
    const onSocketOpen = () => {
        console.log('WebSocket connection opened');
        audioHandlerRef.current.startStreamingPlayback();
        setIsConnected(true);
    };

    /**
     * WebSocket onClose handler.
     */
    const onSocketClose = () => {
        console.log('WebSocket connection closed');
        audioHandlerRef.current.stopStreamingPlayback();
        setIsConnected(false);
    };

    /**
     * WebSocket onError handler.
     * @param {Event} error
     */
    const onSocketError = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
    };

    /**
     * Processes incoming WebSocket messages.
     * @param {string} messageStr - The JSON string received from the backend.
     */
    const JeffProvideHint = async () => {
        let language = "javascript"


        let response = await AskHintInCode(problemStatementRef.current, code, language);
        console.log(response);
        if (response.data.updatedCode) {
            setHintedCode(response.data.updatedCode);
            if (jeffAudioRef.current) {
                jeffAudioRef.current.src = jeffAudio;
                jeffAudioRef.current.play();
            }

        }
    }
    const generateTestCases = async (problemStatement) => {
        let response = await GenerateTestCases(problemStatement);
        if (response.success) {
            setTestCases(response.data)
        }
    }
    useEffect(() => {
        problemStatementRef.current = problemStatement;
        if (problemStatement)
            generateTestCases(problemStatement);
    }, [problemStatement]);

    const SubmitCode = async () => {
        console.log(codeRef.current);
        console.log(testCasesRef.current);
        let response = await runCode(code, testCasesRef.inputTestCase);
        console.log(response);
    }
    const handleFunctionCall = async (text) => {
        let functionToCall = await FunctionCall(text);

        console.log(functionToCall)
        if (functionToCall.data.functionName == "is_problem_statement") {
            let problem = functionToCall.data.arguments;
            problem = JSON.parse(problem)
            setProblemStatement(problem.problem_statement);
        }
        functionToCall = functionToCall.data.functionName

        if (!functionToCall) {
            return;
        }
        if (functionToCall == "ask_hint_in_code") {
            await JeffProvideHint();
        }
        if (functionToCall == "open_code_editor") {
            setCodeEditorState('open');

        }
        if (functionToCall == "close_code_editor") {
            setCodeEditorState('closed');
        }
        if (functionToCall == "submit_code") {

            setCodeEditorState('closed');
            SubmitCode();
        }
    }
    const processMessage = (messageStr) => {
        try {
            const message = JSON.parse(messageStr);

            if (message.type === "conversation.item.input_audio_transcription.completed") {
                console.log(message.transcript);

                setUserResponse(message.transcript);
            }
            if (message.type === "response.audio_transcript.done") {
                console.log(message.transcript);
                handleFunctionCall(message.transcript);
                setAIResponse(message.transcript);
            }
            if (message.type === 'response.audio.delta' && message.delta) {
                const audioChunk = base64ToUint8Array(message.delta);

                if (responseDoneReceivedRef.current) {
                    // Stop current playback and play new audio
                    audioHandlerRef.current.stopStreamingPlayback();
                    audioHandlerRef.current.startStreamingPlayback();
                    responseDoneReceivedRef.current = false;
                }

                setIsAIPlaying(true);
                audioHandlerRef.current.playChunk(audioChunk);

                // Adjust as necessary for accurate timing
                setTimeout(() => {
                    setIsAIPlaying(false);
                }, 500);
            }

            // Handle other message types as needed
        } catch (error) {
            console.error('Error parsing JSON message:', error);
        }
    };

    /**
     * Sends the base64-encoded audio chunk to the backend via WebSocket.
     * @param {string} base64Audio - The base64 encoded audio data.
     */
    const sendAudioChunk = (base64Audio) => {
        if (socketRef.current) {
            const message = {
                type: 'input_audio_buffer.append',
                audio: base64Audio,
                // Include other necessary fields like event_id if required
            };
            socketRef.current.sendMessage(message);
        }
    };

    /**
     * Converts an ArrayBuffer chunk to a Base64 string.
     * @param {ArrayBuffer} buffer
     * @returns {string}
     */
    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    /**
     * Introduces a delay.
     * @param {number} ms - Milliseconds to delay
     * @returns {Promise<void>}
     */


    /**
     * Streams the sample audio to the backend in chunks.
     */
    const sendStartMessage = async () => {
        try {
            try {

                let systemUpdateMessage = {
                    type: "session.update",
                    system_prompt: systemPrompt,
                    interviewType: "coding"

                }
                socketRef.current.sendMessage(systemUpdateMessage);
                try {
                    let data = {
                        interviewId: interviewId,
                        email: email,
                    }

                    const response = await fetch(`${process.env.REACT_APP_PORT}/interview/delete`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            // Include any other headers as needed, e.g., authorization
                        },
                        body: JSON.stringify(data),
                    });

                    if (!response.ok) {
                        throw new Error(`API request failed with status ${response.status}`);
                    }

                    const result = await response.json();
                    console.log('Conversation data sent successfully:', result);

                }
                catch (err) {
                    console.log(err);
                }
            } catch (error) {
                console.error('Error sending conversation data to backend API:', error);
            }
            let text = "Ask me to introduce myself and then start the interview"
            SendTextMessageToWebSocket(socketRef, text);
            await sendPreRecordedAudioToWebSocket(sendAudioChunk, startInterviewMp3)

        } catch (error) {
            console.error('Error in sendStartMessage:', error);
        }
    };

    /**
     * Send conversation data to the backend API when human chat updates.
     */
    useEffect(() => {
        // Only proceed if both userResponse and aiResponse are available
        if (userResponse && aiResponse) {
            const dataToSend = {
                interviewId: interviewId,
                email: email,
                conversation: {
                    user: userResponse,
                    ai: aiResponse
                },
                isNewInterview: false
            };
            console.log(dataToSend);
            // Send the conversation data to the backend
            sendConversationData(dataToSend);
        }
    }, [userResponse]); // Trigger when userResponse updates

    /**
     * Function to send conversation data to the backend API.
     * @param {Object} data - The conversation data object containing aiChat and userChat.
     */
    const sendConversationData = async (data) => {
        try {

            const response = await fetch(`${process.env.REACT_APP_PORT}/interview/start`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any other headers as needed, e.g., authorization
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();
            console.log('Conversation data sent successfully:', result);
        } catch (error) {
            console.error('Error sending conversation data to backend API:', error);
        }
    };

    /**
     * Timer Effect: Decrements the timer every second and ends the interview when it reaches zero.
     */
    useEffect(() => {
        if (timer <= 0) {
            return;
        }

        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(interval);
                    EndInterview(); // End interview when timer reaches zero
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000); // 1000ms = 1 second

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [timer]);

    /**
     * Formats the timer from seconds to MM:SS format.
     * @param {number} seconds
     * @returns {string}
     */
    const formatTimer = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
            <audio ref={jeffAudioRef} />
            {/* Display the Timer */}
            {/* You can display the timer here or within the VideoInterface component */}
            {screen === 'starting' && (
                <CodingInterviewStartPage
                    isConnected={isConnected}
                    startRecording={startRecording}
                    sendStartMessage={sendStartMessage}
                    setScreen={setScreen}
                />
            )}
            {screen === 'interview' && (
                <VideoInterface
                    isAISpeaking={isAIPlaying}
                    timer={formatTimer(timer)}
                    codeEditorState={codeEditorState}
                    code={code}
                    setCode={setCode}
                    onEndInterview={EndInterview}
                    problemStatement={problemStatement}
                    setCodeEditorState={setCodeEditorState}
                    language={language}
                    setLanguage={setLanguage}
                    hintedCode={hintedCode} // Pass EndInterview as a prop
                    micMute={micMute}
                    setMicMute={setMicMute}
                />
            )}
        </div>
    );
}

export default CodingInterviewInterface;
