import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createAIInterviewRecordingEntry } from '../../services/api';
import { FaMicrophone, FaVideo, FaVolumeUp, FaSpinner } from 'react-icons/fa';
import { CreateVideoBlob, deleteVideoBlob } from '../../services/api2';
const InterviewStartPage = ({ isConnected, startRecording, sendStartMessage, setScreen }) => {
  const [devices, setDevices] = useState({
    mic: false,
    speaker: false,
    camera: false
  });
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [interviewId, setInterviewId] = useState(null);
  const [email, setEmail] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const interviewId = searchParams.get('id');
    const email = localStorage.getItem('email');
    if (interviewId && email) {
      setInterviewId(interviewId);
      setEmail(email);
    }
  }, [location]);
  const checkMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      setDevices(prev => ({ ...prev, mic: true }));
    } catch (err) {
      setDevices(prev => ({ ...prev, mic: false }));
    }
  };

  const checkCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        }
      });
      setStream(mediaStream);
      setDevices(prev => ({ ...prev, camera: true }));
    } catch (err) {
      setDevices(prev => ({ ...prev, camera: false }));
    }
  };

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  const checkSpeakers = async () => {
    try {
      const audio = new Audio('data:audio/wav;base64,UklGRjIAAABXQVZFZm10IBIAAAABAAEAQB8AAEAfAAABAAgAAABmYWN0BAAAAAAAAABkYXRhAAAAAA==');
      await audio.play();
      setDevices(prev => ({ ...prev, speaker: true }));
    } catch (err) {
      setDevices(prev => ({ ...prev, speaker: false }));
    }
  };

  const allDevicesChecked = devices.mic && devices.speaker;

  const startInterview = async () => {
    if (allDevicesChecked && isConnected) {
      try {
        try {
          await startRecording();

        }
        catch (err) {
          console.log(err)
        }
        try {
          await sendStartMessage();

        }
        catch (err) {
          console.log(err)
        }
        try {

        }
        catch (err) {
          console.log(err)
        }
        try {

          await createAIInterviewRecordingEntry(email, interviewId);
        }
        catch (err) {
          console.log(err)
        }
        try {
          let response = await deleteVideoBlob(interviewId, email);

        }
        catch (err) {
          console.log(err)
        }
        try {
          await CreateVideoBlob(interviewId, email);

        }
        catch (err) {
          console.log(err)
        }




      }
      catch (err) {

      }
      setScreen('interview');
    }
  };

  return (
    <div className="min-h-screen bg-light-gray">
      {/* Header - Enhanced with better spacing and gradient */}
      <div className="">
        <div className="container mx-auto px-6 py-8">
          <img
            src="https://easecruit.com/images/Logo.png"
            alt="Easecruit Logo"
            className="h-10 mb-8"
          />
          <h1 className="text-3xl font-semibold text-gray-800 mb-3">Technical Interview Setup</h1>
          <p className="text-gray-600 text-lg">Complete the system check before starting your interview</p>
        </div>
      </div>

      {/* Connection Status - Improved visibility */}
      {!isConnected && (
        <div className="bg-indigo-50 border-b border-indigo-100">
          <div className="container mx-auto px-6 py-3 flex items-center gap-3">
            <FaSpinner className="animate-spin text-indigo-600" />
            <span className="text-sm text-indigo-700 font-medium">Connecting to server...</span>
          </div>
        </div>
      )}

      <div className="container mx-auto px-6 py-12">
        <div className="grid lg:grid-cols-2 gap-12 max-w-7xl mx-auto">
          {/* Camera Preview - Enhanced with better styling */}
          <div className=" overflow-hidden">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-xl font-semibold text-gray-800">Camera Preview</h2>
            </div>
            <div className="aspect-video bg-gray-900">
              {devices.camera ? (
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  muted
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="h-full flex items-center justify-center text-gray-400">
                  <span className="text-lg">Camera preview will appear here</span>
                </div>
              )}
            </div>
          </div>

          {/* Device Checks - Improved card design */}
          <div className="">
            <h2 className="text-2xl font-semibold text-gray-800 mb-11">System Check</h2>

            {[
              {
                icon: <FaMicrophone className="text-xl" />,
                title: 'Microphone',
                isChecked: devices.mic,
                onCheck: checkMicrophone
              },
              {
                icon: <FaVolumeUp className="text-xl" />,
                title: 'Speakers',
                isChecked: devices.speaker,
                onCheck: checkSpeakers
              },
              {
                icon: <FaVideo className="text-xl" />,
                title: 'Camera',
                isChecked: devices.camera,
                onCheck: checkCamera
              }
            ].map((device, index) => (
              <div key={index} className=" py-2 px-5 transition-shadow">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-5">
                    <div className="p-4 bg-indigo-50 rounded-lg text-indigo-600">
                      {device.icon}
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 mb-1">{device.title}</h3>
                      <p className="text-sm text-gray-500">{device.description}</p>
                    </div>
                  </div>
                  <button
                    onClick={device.onCheck}
                    className={`px-5 py-2.5 rounded-lg ${device.isChecked
                      ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                      : 'bg-white border-2 rounded-full text-gray-700 hover:bg-gray-200'
                      }`}
                  >
                    {device.isChecked ? 'Connected ✓' : `Check ${device.title}`}
                  </button>
                </div>
              </div>
            ))}

            {/* Warning Message - Improved visibility */}
            {!allDevicesChecked && (
              <div className="bg-amber-50 p-3 mt-3 rounded-lg border border-amber-200">
                <div className="flex items-center text-amber-700">

                  <span className="font-medium">Please check all your devices before starting the interview</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Start Button - Enhanced with better sizing and hover effects */}
        <div className="max-w-7xl mx-auto mt-12">
          <button
            onClick={startInterview}
            disabled={!allDevicesChecked || !isConnected}
            className={`max-w-md mx-auto flex justify-center items-center py-3 px-8 rounded-lg text-white font-medium transition-all transform hover:scale-105 ${allDevicesChecked && isConnected
              ? 'bg-indigo-600 hover:bg-indigo-700 shadow-md hover:shadow-lg'
              : 'bg-gray-300 cursor-not-allowed'
              }`}
          >
            {!isConnected
              ? 'Connecting to Server...'
              : allDevicesChecked
                ? 'Start Interview'
                : 'Complete System Check to Continue'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InterviewStartPage;