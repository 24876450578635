// src/services/api.js

import axios from 'axios';

/**
 * Uploads a media chunk to the server.
 * @param {string} mediaType - The type of media ('video', 'human-audio', 'ai-audio').
 * @param {FormData} formData - The form data containing the media chunk.
 * @returns {Promise<object>} - The server response.
 */
let port = `https://realtimeapi.easecruit.com`
export const CreateVideoBlob = async (interviewId, email, createdAt) => {
    const response = await axios.post(`${port}/media/create`, {
        interviewId,
        email,
        containerName: "videos"
    });
    return response.data;
}
export const CommitVideoBlob = async (interviewId, email) => {
    const response = await axios.post(`${port}/media/commit`, {
        interviewId,
        email,
        containerName: "videos"
    });
    return response.data;
}
export const deleteVideoBlob = async (interviewId, email) => {
    const response = await axios.delete(`${port}/media/delete`, {
        data: {
            interviewId,
            email,
            containerName: "videos"
        }
    });
    return response.data;

}
export const UploadVideoData = async (formData) => {
    let response = await axios.post(`${port}/media/append`, formData, {
        'Content-Type': 'multipart/form-data'
    });
    return response.data
}

export const getBlobUrl = async (interviewId, email) => {
    let response = await axios.get(`${port}/media/url`, {
        params: {
            interviewId,
            email,
            containerName: "videos"
        }
    })
    return response.data
}

