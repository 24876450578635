// src/AIInterviewPlayer.js
import React, { useEffect, useRef, useState } from "react";
import {
    FetchAIAudios,
    fetchProctoringResults,
    getVideoChunks,
    getVideoUrl
} from "../../services/api";
import {
    base64ToUint8Array,
    createSilenceBuffer,
    decodeAudio,
    mergeAudioBuffers,
    parseTimeToSeconds
} from "../../utils/audioUtils";

// Importing icons from lucide-react
import {
    PlayCircle,
    Play,
    Pause,
    PauseCircle,
    Volume2,
    VolumeX,
    SkipBack,  // Alternative option  
    SkipForward,  // Alternative option  
    Maximize2,
    Minimize2,
    AlertCircle
} from "lucide-react";
import { getBlobUrl } from "../../services/api2";

const DemoVideo = ({ interviewId = "32f03d5c-002c-4ee0-b860-778a8d26cebc", email = "kartik@gmail.com" }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [audioContext, setAudioContext] = useState(null);
    const [mergedAudioBuffer, setMergedAudioBuffer] = useState(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isAudioReady, setIsAudioReady] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [proctoringResults, setProctoringResults] = useState([]);
    const [currentProctoringResult, setCurrentProctoringResult] = useState(null);
    const [proctoringMarkers, setProctoringMarkers] = useState([]);
    const [baseTime, setBaseTime] = useState(null);
    const [volume, setVolume] = useState(90);
    const [playbackRate, setPlaybackRate] = useState(1.0); // New state for playback speed
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [showVolumeSlider, setShowVolumeSlider] = useState(false); // State for volume slider visibility

    // New States for iOS Detection and AudioContext State Tracking
    const [isIOS, setIsIOS] = useState(false);
    const [audioContextState, setAudioContextState] = useState(null);

    // Refs for audio nodes and video element
    const videoRef = useRef(null);
    const mediaElementSourceRef = useRef(null);
    const aiAudioSourceRef = useRef(null);
    const gainNodeRef = useRef(null);
    const animationFrameRef = useRef(null);
    const volumeSliderRef = useRef(null); // Ref for volume slider

    const markerStyles = `
      .marker-tooltip {
        visibility: hidden;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(10px);
        background-color: rgba(17, 24, 39, 0.95);
        color: white;
        padding: 12px;
        border-radius: 8px;
        font-size: 13px;
        z-index: 50;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(8px);
        transition: all 0.2s ease;
        opacity: 0;
        pointer-events: none;
        min-width: 200px;
      }

      .marker-container:hover .marker-tooltip {
        visibility: visible;
        transform: translateX(-50%) translateY(-10px);
        opacity: 1;
      }

      .marker-container:hover .marker-dot {
        transform: scale(1.5) translateX(-30%) translateY(-30%);
        box-shadow: 0 0 12px rgba(99, 102, 241, 0.5);
      }

      .marker-dot {
        transition: all 0.2s ease;
      }
    `;

    // Detect if the device is iOS
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIOS(true);
        }
    }, []);

    // Track AudioContext state changes
    useEffect(() => {
        if (audioContext) {
            setAudioContextState(audioContext.state);
            const checkState = () => {
                if (audioContext.state !== audioContextState) {
                    setAudioContextState(audioContext.state);
                }
            };
            const interval = setInterval(checkState, 500); // Check every 500ms

            return () => {
                clearInterval(interval);
            };
        }
    }, [audioContext, audioContextState]);

    // Handle clicks outside the volume slider to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                volumeSliderRef.current &&
                !volumeSliderRef.current.contains(event.target) &&
                !event.target.closest(".volume-button")
            ) {
                setShowVolumeSlider(false);
            }
        };

        if (showVolumeSlider) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showVolumeSlider]);

    const handleVolumeChange = (e) => {
        const newVolume = parseInt(e.target.value);
        setVolume(newVolume);
        if (gainNodeRef.current) {
            gainNodeRef.current.gain.value = newVolume / 100;
        }
    };

    // Initialize AudioContext on mount
    useEffect(() => {
        const context = new (window.AudioContext || window.webkitAudioContext)();
        setAudioContext(context);

        return () => {
            if (context) {
                context.close();
            }
        };
    }, []);

    useEffect(() => {
        const fetchProctoringImages = async () => {
            try {
                let response = await fetchProctoringResults(interviewId, email);
                if (!response.error) {
                    setProctoringResults(response.images);
                }
            }
            catch (err) {
                setError("Failed to fetch proctoring results");
                console.error(err);
            }
        };
        fetchProctoringImages();
    }, [interviewId, email]);

    useEffect(() => {
        if (proctoringResults.length > 0 && duration > 0 && baseTime != null) {
            const markers = proctoringResults.map(result => {
                const totalSeconds = parseTimeToSeconds(result.timeStamp);
                return {
                    time: parseInt(totalSeconds - baseTime),
                    result: result
                };
            }).filter(marker => marker.time <= duration);
            setProctoringMarkers(markers);
        }
    }, [proctoringResults, duration, baseTime]);

    useEffect(() => {
        if (proctoringMarkers.length > 0) {
            const activeMarker = proctoringMarkers.find(marker =>
                Math.abs(marker.time - currentTime) < 0.5
            );
            setCurrentProctoringResult(activeMarker ? activeMarker.result : null);
        }
    }, [currentTime, proctoringMarkers]);

    const renderProctoringResultDetails = () => {
        if (!currentProctoringResult) return null;
        // Currently empty - can be expanded as needed.
        return null;
    };

    // Fetch AI audio when AudioContext is ready
    useEffect(() => {
        if (audioContext) {
            fetchAndProcessChunks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioContext]);

    // Fetch video URL on mount
    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const originalVideoUrl = await getBlobUrl(interviewId, email);
                const response = await getVideoChunks(originalVideoUrl.url);
                setVideoUrl(response.proxiedVideoUrl);
            } catch (err) {
                setError("Failed to fetch video URL");
                console.error(err);
            }
        };

        fetchVideoUrl();
    }, [interviewId, email]);

    const fetchAndProcessChunks = async () => {
        setIsFetching(true);
        setError(null);
        try {
            const response = await FetchAIAudios(email, interviewId);
            const { createdAt, data, endAt } = response;
            if (!createdAt || !data) {
                throw new Error("Invalid response format");
            }

            const baseTimeSec = parseTimeToSeconds(createdAt);
            const endTimeSec = parseTimeToSeconds(endAt);
            setBaseTime(baseTimeSec);
            if (baseTimeSec && endTimeSec) {
                setDuration(parseInt(51070.014 - baseTimeSec));
            }

            const processedGroups = data
                .map((groupData) => {
                    const { startTime, chunks } = groupData;
                    if (!startTime || !chunks) return null;
                    const groupStartTimeSec = parseTimeToSeconds(startTime) - baseTimeSec;
                    if (groupStartTimeSec < 0) return null;
                    return {
                        startTimeSec: groupStartTimeSec,
                        chunks: chunks.map((chunk) => ({
                            audioChunk: chunk,
                        })),
                    };
                })
                .filter(Boolean)
                .sort((a, b) => a.startTimeSec - b.startTimeSec);

            // Decode all chunks
            const decodedGroups = await Promise.all(
                processedGroups.map(async (group) => {
                    const decodedChunks = await Promise.all(
                        group.chunks.map(async (chunk) => {
                            const uintArray = base64ToUint8Array(chunk.audioChunk);
                            const decodedAudio = await decodeAudio(audioContext, uintArray);
                            return decodedAudio;
                        })
                    );
                    return {
                        ...group,
                        decodedChunks,
                    };
                })
            );

            let mergedBuffers = [];
            let previousEndTime = 0;

            for (const group of decodedGroups) {
                const silenceDuration = group.startTimeSec - previousEndTime;
                if (silenceDuration > 0) {
                    const silenceBuffer = createSilenceBuffer(silenceDuration);
                    mergedBuffers.push(silenceBuffer);
                    previousEndTime += silenceDuration;
                }

                for (const decodedChunk of group.decodedChunks) {
                    mergedBuffers.push(decodedChunk);
                    previousEndTime += decodedChunk.duration;
                }
            }

            const mergedAudio = mergeAudioBuffers(mergedBuffers);
            setMergedAudioBuffer(mergedAudio);
            setIsAudioReady(true);
        } catch (err) {
            setError(err.message || "Failed to fetch audio chunks");
            console.error("Fetch error:", err);
        } finally {
            setIsFetching(false);
        }
    };

    const handlePlayPause = async () => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        if (!isPlaying) {
            if (!audioContext || !mergedAudioBuffer || !videoUrl) {
                console.warn("AudioContext, audio data, or video URL not ready yet.");
                return;
            }

            try {
                if (audioContext.state === "suspended") {
                    await audioContext.resume();
                }

                if (!mediaElementSourceRef.current) {
                    mediaElementSourceRef.current = audioContext.createMediaElementSource(videoElement);
                }

                if (!gainNodeRef.current) {
                    gainNodeRef.current = audioContext.createGain();
                    gainNodeRef.current.gain.value = volume / 100;
                    mediaElementSourceRef.current.connect(gainNodeRef.current);
                    gainNodeRef.current.connect(audioContext.destination);
                }

                aiAudioSourceRef.current = audioContext.createBufferSource();
                aiAudioSourceRef.current.buffer = mergedAudioBuffer;
                aiAudioSourceRef.current.loop = false;
                aiAudioSourceRef.current.playbackRate.value = playbackRate; // Synchronize playback rate

                aiAudioSourceRef.current.connect(gainNodeRef.current);

                const currentTime = videoElement.currentTime;
                aiAudioSourceRef.current.start(0, currentTime);

                videoElement.playbackRate = playbackRate;
                await videoElement.play();

                animationFrameRef.current = requestAnimationFrame(updateCurrentTime);

                setIsPlaying(true);
            } catch (err) {
                setError(`Playback failed: ${err.message}`);
                console.error("Playback error:", err);
            }
        } else {
            videoElement.pause();

            if (aiAudioSourceRef.current) {
                try {
                    aiAudioSourceRef.current.stop();
                    aiAudioSourceRef.current.disconnect();
                } catch (e) {
                    console.warn("AI Audio Source already stopped.");
                }
                aiAudioSourceRef.current = null;
            }

            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }

            setIsPlaying(false);
        }
    };

    const updateCurrentTime = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            setCurrentTime(videoElement.currentTime);
            animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
        }
    };

    const handleSeeked = () => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        setCurrentTime(videoElement.currentTime);

        if (isPlaying) {
            if (aiAudioSourceRef.current) {
                try {
                    aiAudioSourceRef.current.stop();
                    aiAudioSourceRef.current.disconnect();
                } catch (e) {
                    console.warn("AI Audio Source already stopped.");
                }
                aiAudioSourceRef.current = null;
            }

            aiAudioSourceRef.current = audioContext.createBufferSource();
            aiAudioSourceRef.current.buffer = mergedAudioBuffer;
            aiAudioSourceRef.current.loop = false;
            aiAudioSourceRef.current.playbackRate.value = playbackRate; // Keep rate in sync
            aiAudioSourceRef.current.connect(gainNodeRef.current);

            const currentTime = videoElement.currentTime;
            aiAudioSourceRef.current.start(0, currentTime);
        }
    };

    const handleSeek = (e) => {
        const seekTime = parseFloat(e.target.value);
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.currentTime = seekTime;
            setCurrentTime(seekTime);
        }
    };

    const skipForward = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.currentTime = Math.min(videoElement.currentTime + 10, duration);
            setCurrentTime(videoElement.currentTime);
        }
    };

    const skipBackward = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.currentTime = Math.max(videoElement.currentTime - 10, 0);
            setCurrentTime(videoElement.currentTime);
        }
    };

    const handleLoadedMetadata = () => {
        setIsVideoLoaded(true);
    };

    const handleCanPlayThrough = () => {
        setIsVideoLoaded(true);
    };

    useEffect(() => {
        return () => {
            if (aiAudioSourceRef.current) {
                try {
                    aiAudioSourceRef.current.stop();
                    aiAudioSourceRef.current.disconnect();
                } catch (e) {
                    console.warn("AI Audio Source already stopped.");
                }
                aiAudioSourceRef.current = null;
            }
            if (mediaElementSourceRef.current) {
                mediaElementSourceRef.current.disconnect();
                mediaElementSourceRef.current = null;
            }
            if (gainNodeRef.current) {
                gainNodeRef.current.disconnect();
                gainNodeRef.current = null;
            }
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [audioContext]);

    const isReadyToPlay = isVideoLoaded && isAudioReady;

    const formatTime = (time) => {
        if (isNaN(time)) return "00:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutesStr}:${secondsStr}`;
    };

    // Handle playback rate change
    const handlePlaybackRateChange = (e) => {
        const newRate = parseFloat(e.target.value);
        setPlaybackRate(newRate);

        const videoElement = videoRef.current;
        if (videoElement) {
            videoElement.playbackRate = newRate;
        }

        // If currently playing, adjust the AI audio source's playbackRate
        if (aiAudioSourceRef.current) {
            aiAudioSourceRef.current.playbackRate.value = newRate;
        }
    };

    // Handle fullscreen toggle
    const handleFullscreenToggle = () => {
        const videoContainer = videoRef.current.parentElement; // Assuming video is directly inside container
        if (!videoContainer) return;

        if (!document.fullscreenElement && !document.webkitFullscreenElement) {
            // Attempt to enter fullscreen
            if (videoContainer.requestFullscreen) {
                videoContainer.requestFullscreen();
            } else if (videoContainer.webkitRequestFullscreen) {
                // For Safari on iOS
                videoContainer.webkitRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            // Exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };

    // New Handler to Resume AudioContext and Start Playback on iOS
    const handleIOSPlay = async () => {
        if (audioContext && audioContext.state === 'suspended') {
            try {
                await audioContext.resume();
                // Update the AudioContext state manually since there's no event
                setAudioContextState(audioContext.state);
                handlePlayPause(); // Start playback
            } catch (err) {
                setError(`Failed to resume audio: ${err.message}`);
                console.error("AudioContext resume error:", err);
            }
        }
    };

    return (
        <div className="">
            <style>{markerStyles}</style>

            <div className="max-w-[1080px] mx-auto p-4">
                <div className="flex flex-col items-center">
                    <div className="relative group w-full md:w-4/5 mx-auto">
                        <div className="relative aspect-video bg-gradient-to-b from-gray-900 to-black rounded-xl overflow-hidden shadow-2xl">
                            <video
                                ref={videoRef}
                                src={videoUrl}
                                className="w-full h-full object-contain"
                                crossOrigin="anonymous"
                                onSeeked={handleSeeked}
                                onLoadedMetadata={handleLoadedMetadata}
                                onCanPlayThrough={handleCanPlayThrough}
                                preload="auto"
                                playsInline
                                webkit-playsinline="true"
                            // Removed 'controls' to hide default controls
                            />

                            <div className="absolute bottom-0 left-0 right-0 w-full bg-gradient-to-t from-black/90 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300">
                                <div className="absolute -top-3 left-0 right-0 h-3 cursor-pointer group">
                                    <div className="absolute bottom-0 left-0 right-0 h-[3px] group-hover:h-[6px] transition-all duration-200">
                                        <div className="absolute w-full h-full bg-white/30" />
                                        <div
                                            className="absolute h-full bg-white/40"
                                            style={{ width: '100%' }}
                                        />
                                        <div
                                            className="relative h-full bg-[#ff0000]"
                                            style={{ width: `${(currentTime / duration) * 100}%` }}
                                        >
                                            <div className="absolute -right-[7px] top-1/2 -translate-y-1/2 w-[14px] h-[14px] bg-[#ff0000] rounded-full scale-0 group-hover:scale-100 transition-transform duration-200" />
                                        </div>

                                        {proctoringMarkers.map((marker, index) => (
                                            <div
                                                key={index}
                                                className="marker-container absolute top-1/2 cursor-pointer"
                                                style={{
                                                    left: `${(marker.time / duration) * 100}%`,
                                                }}
                                            >
                                                <div className="marker-tooltip">
                                                    <div className="flex flex-col gap-2">
                                                        <div className="text-xs text-gray-400 mb-1">
                                                            Time: {formatTime(marker.time)}
                                                        </div>
                                                        <div className="space-y-2">
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Mobile Detection:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.isPersonHoldingMobile
                                                                    ? 'bg-red-500/20 text-red-400'
                                                                    : 'bg-green-500/20 text-green-400'
                                                                    }`}>
                                                                    {marker.result.isPersonHoldingMobile ? 'Detected' : 'Clear'}
                                                                </span>
                                                            </div>
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Camera Focus:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.isPersonWatchingInCamera
                                                                    ? 'bg-green-500/20 text-green-400'
                                                                    : 'bg-red-500/20 text-red-400'
                                                                    }`}>
                                                                    {marker.result.isPersonWatchingInCamera ? 'Focused' : 'Not Focused'}
                                                                </span>
                                                            </div>
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Person Count:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.numberOfPerson > 1
                                                                    ? 'bg-red-500/20 text-red-400'
                                                                    : 'bg-green-500/20 text-green-400'
                                                                    }`}>
                                                                    {marker.result.numberOfPerson} {marker.result.numberOfPerson > 1 ? 'People' : 'Person'}
                                                                </span>
                                                            </div>
                                                            <div className="flex items-center justify-between">
                                                                <span className="text-gray-300">Mask Status:</span>
                                                                <span className={`px-2 py-0.5 rounded text-xs font-medium ${marker.result.isPersonWearingMask
                                                                    ? 'bg-green-500/20 text-green-400'
                                                                    : 'bg-red-500/20 text-red-400'
                                                                    }`}>
                                                                    {marker.result.isPersonWearingMask ? 'Wearing' : 'Not Wearing'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 pt-2 border-t border-gray-700">
                                                            <div className={`text-center text-sm font-medium ${marker.result.isPersonHoldingMobile ||
                                                                !marker.result.isPersonWatchingInCamera ||
                                                                marker.result.numberOfPerson > 1
                                                                ? 'text-red-400'
                                                                : 'text-green-400'
                                                                }`}>
                                                                {marker.result.isPersonHoldingMobile ||
                                                                    !marker.result.isPersonWatchingInCamera ||
                                                                    marker.result.numberOfPerson > 1
                                                                    ? '⚠️ Violations Detected'
                                                                    : '✅ All Clear'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {duration != null && (
                                        <input
                                            type="range"
                                            min="0"
                                            max={duration}
                                            value={currentTime}
                                            onChange={handleSeek}
                                            className="absolute bottom-0 left-0 w-full opacity-0 cursor-pointer"
                                            style={{ height: '24px', margin: 0 }}
                                        />
                                    )}
                                </div>

                                <div className="relative px-6 py-4">
                                    {/* Responsive Controller Section */}
                                    <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
                                        <div className="flex flex-wrap items-center space-x-4 md:space-x-6">
                                            {/* Play/Pause Button with enhanced styling */}
                                            <button
                                                onClick={handlePlayPause}
                                                className="text-white hover:text-primary-400 transition-all duration-200 transform hover:scale-110 focus:outline-none p-2"
                                                disabled={!isReadyToPlay || isFetching}
                                                aria-label={isPlaying ? "Pause" : "Play"}
                                            >
                                                {isPlaying ? (
                                                    <Pause className="w-8 h-8 fill-current" />
                                                ) : (
                                                    <Play className="w-8 h-8 fill-current" />
                                                )}
                                            </button>

                                            {/* Skip buttons with enhanced styling */}
                                            <div className="flex items-center gap-2">
                                                <button
                                                    onClick={skipBackward}
                                                    className="text-white hover:text-primary-400 transition-all duration-200 transform hover:scale-110 focus:outline-none flex items-center gap-1 p-2"
                                                    aria-label="Skip Backward 10 Seconds"
                                                >
                                                    <SkipBack className="w-5 h-5" />
                                                    <span className="text-xs">10s</span>
                                                </button>
                                                <button
                                                    onClick={skipForward}
                                                    className="text-white hover:text-primary-400 transition-all duration-200 transform hover:scale-110 focus:outline-none flex items-center gap-1 p-2"
                                                    aria-label="Skip Forward 10 Seconds"
                                                >
                                                    <SkipForward className="w-5 h-5" />
                                                    <span className="text-xs">10s</span>
                                                </button>
                                            </div>

                                            {/* Volume Control with enhanced styling */}
                                            <div className="flex items-center relative group">
                                                <button
                                                    className="text-white hover:text-primary-400 transition-all duration-200 transform hover:scale-110 focus:outline-none volume-button p-2"
                                                    onClick={() => setShowVolumeSlider(prev => !prev)}
                                                    aria-label="Volume Control"
                                                >
                                                    {volume > 0 ? (
                                                        <Volume2 className="w-5 h-5" />
                                                    ) : (
                                                        <VolumeX className="w-5 h-5" />
                                                    )}
                                                </button>

                                                {showVolumeSlider && (
                                                    <div
                                                        ref={volumeSliderRef}
                                                        className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-gray-900/95 p-3 rounded-lg shadow-lg z-50 backdrop-blur-sm border border-white/10"
                                                    >
                                                        <input
                                                            type="range"
                                                            className="w-32 accent-primary-500"
                                                            min="0"
                                                            max="100"
                                                            value={volume}
                                                            onChange={handleVolumeChange}
                                                            aria-label="Volume Slider"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* Time Display */}
                                        <div className="flex items-center text-sm text-white font-medium">
                                            <span>{formatTime(currentTime)}</span>
                                            <span className="mx-1 opacity-60">/</span>
                                            <span className="opacity-60">{formatTime(duration)}</span>
                                        </div>

                                        {/* Right side controls */}
                                        <div className="flex flex-wrap items-center space-x-4">
                                            {/* Playback Speed Selector with enhanced styling */}
                                            <select
                                                value={playbackRate}
                                                onChange={handlePlaybackRateChange}
                                                className="bg-gray-900/80 text-white text-sm rounded-lg px-3 py-1.5 focus:outline-none border border-white/10 hover:bg-gray-800/80 transition-colors"
                                                aria-label="Playback Speed"
                                            >
                                                <option value="0.5">0.5x</option>
                                                <option value="0.75">0.75x</option>
                                                <option value="1">1x</option>
                                                <option value="1.25">1.25x</option>
                                                <option value="1.5">1.5x</option>
                                                <option value="2">2x</option>
                                            </select>

                                            {/* Fullscreen Toggle with enhanced styling */}
                                            <button
                                                onClick={handleFullscreenToggle}
                                                className="text-white hover:text-primary-400 transition-all duration-200 transform hover:scale-110 focus:outline-none p-2"
                                                aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
                                            >
                                                {!isFullscreen ? (
                                                    <Maximize2 className="w-6 h-6" />
                                                ) : (
                                                    <Minimize2 className="w-6 h-6" />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    {/* End of Responsive Controller Section */}
                                </div>
                            </div>
                        </div>

                        {/* Conditional Loading Overlay */}
                        {(isFetching || !isReadyToPlay) && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black/60 rounded-xl">
                                {isIOS && audioContextState === 'suspended' ? (
                                    <button
                                        onClick={handleIOSPlay}
                                        className="text-white bg-primary-500 hover:bg-primary-600 transition-all duration-200 transform hover:scale-105 focus:outline-none p-4 rounded-full"
                                        aria-label="Start Playback"
                                    >
                                        <Play className="w-12 h-12 fill-current" />
                                    </button>
                                ) : (
                                    <div className="animate-spin rounded-full h-12 w-12 border-4 border-white border-t-transparent" />
                                )}
                            </div>
                        )}

                        {/* Error Message */}
                        {error && (
                            <div className="mt-4 p-4 bg-red-900/50 text-red-100 rounded-xl border border-red-500">
                                <div className="flex items-center space-x-2">
                                    <svg
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                    <div>
                                        <p className="font-medium">Error occurred</p>
                                        <p className="text-sm opacity-90">{error}</p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Proctoring Details Panel */}
                        {/* {renderProctoringResultDetails()} */}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default DemoVideo;
    

