// src/handlers/AudioHandler.js
export default class AudioHandler {
    constructor(audioTrack) {
        this.context = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 24000 });
        this.workletNode = null;
        this.source = null;
        this.audioTrack = audioTrack; // The individual audio track
        this.sampleRate = 24000;

        this.nextPlayTime = 0;
        this.isPlaying = false;
        this.playbackQueue = [];
    }

    async initialize() {
        try {
            await this.context.audioWorklet.addModule("/audio-processor.js");
            console.log("AudioWorklet module loaded.");
        } catch (error) {
            console.error("Error loading AudioWorklet module:", error);
        }
    }

    async startRecording(onChunk) {
        try {
            await this.context.resume();
            this.source = this.context.createMediaStreamSource(new MediaStream([this.audioTrack]));
            this.workletNode = new AudioWorkletNode(this.context, "audio-recorder-processor");

            this.workletNode.port.onmessage = (event) => {
                if (event.data.eventType === "audio") {
                    const float32Data = event.data.audioData;
                    const int8Data = new Int8Array(float32Data.length);

                    for (let i = 0; i < float32Data.length; i++) {
                        const s = Math.max(-1, Math.min(1, float32Data[i]));
                        int8Data[i] = s < 0 ? s * 0x80 : s * 0x7f;
                    }

                    const uint8Data = new Uint8Array(int8Data.buffer);
                    onChunk(uint8Data);
                }
            };

            this.source.connect(this.workletNode);
            this.workletNode.connect(this.context.destination);

            this.workletNode.port.postMessage({ command: "START_RECORDING" });
        } catch (error) {
            console.error("Error starting recording:", error);
            throw error;
        }
    }

    stopRecording() {
        if (!this.workletNode || !this.source) {
            throw new Error("Recording not started");
        }

        this.workletNode.port.postMessage({ command: "STOP_RECORDING" });

        this.workletNode.disconnect();
        this.source.disconnect();
    }

    startStreamingPlayback() {
        this.isPlaying = true;
        this.nextPlayTime = this.context.currentTime;
    }

    stopStreamingPlayback() {
        this.isPlaying = false;
        this.playbackQueue.forEach((source) => source.stop());
        this.playbackQueue = [];
    }

    playChunk(chunk) {
        if (!this.isPlaying) return;

        const int16Data = new Int16Array(chunk.buffer);

        const float32Data = new Float32Array(int16Data.length);
        for (let i = 0; i < int16Data.length; i++) {
            float32Data[i] = int16Data[i] / (int16Data[i] < 0 ? 0x8000 : 0x7fff);
        }

        const audioBuffer = this.context.createBuffer(
            1,
            float32Data.length,
            this.sampleRate
        );
        audioBuffer.getChannelData(0).set(float32Data);

        const source = this.context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(this.context.destination);

        const chunkDuration = audioBuffer.length / this.sampleRate;

        source.start(this.nextPlayTime);

        this.playbackQueue.push(source);
        source.onended = () => {
            const index = this.playbackQueue.indexOf(source);
            if (index > -1) {
                this.playbackQueue.splice(index, 1);
            }
        };

        this.nextPlayTime += chunkDuration;

        if (this.nextPlayTime < this.context.currentTime) {
            this.nextPlayTime = this.context.currentTime;
        }
    }

    async close() {
        this.workletNode?.disconnect();
        this.source?.disconnect();
        await this.context.close();
    }
}
