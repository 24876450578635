import React, { useRef, useState } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";

const MonacoEditorWithHints = () => {
  const editorRef = useRef(null);
  const monaco = useMonaco();
  const [currentHighlightedLine, setCurrentHighlightedLine] = useState(null);
  const decorationRef = useRef([]); // To store current decorations

  const originalCode = `
function processArray(arr) {
  let result = [];
  for (let i = 0; i < arr.length; i++) {
    result.push(arr[i] * 2);
  }
  return result;
}

`;

  const hintedCode = `
function processArray(arr) {
  // Hint: Create a new array to store the result
  let result = [];
  // Hint: Use a loop to traverse the input array
  for (let i = 0; i < arr.length; i++) {
    // Hint: Multiply each element by 2 before pushing to the result array
    result.push(arr[i] * 2);
  }
  // Hint: Return the result array
  return result;
}

`;

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    if (monaco) {
      // Initially set all lines as unwatched (gray)
      updateLineColors(originalCode.split("\n").map((_, index) => index));
    }
  };

  const updateLineColors = (grayLines) => {
    if (!monaco || !editorRef.current) return;

    // Remove all existing color decorations
    editorRef.current.deltaDecorations([], []);

    // Apply gray color to unwatched lines
    const colorDecorations = grayLines.map((line) => ({
      range: new monaco.Range(line + 1, 1, line + 1, 1),
      options: {
        inlineClassName: "unwatched-line",
      },
    }));

    editorRef.current.deltaDecorations([], colorDecorations);
  };

  const updateLineHighlight = (lineNumber) => {
    if (!monaco || !editorRef.current) return;

    // Remove previous highlight decorations
    if (decorationRef.current.length > 0) {
      decorationRef.current = editorRef.current.deltaDecorations(decorationRef.current, []);
    }

    if (lineNumber !== null) {
      // Add new decoration for the current line
      const newDecorations = [
        {
          range: new monaco.Range(lineNumber + 1, 1, lineNumber + 1, 1),
          options: {
            isWholeLine: true,
            className: "highlighted-line",
            linesDecorationsClassName: "highlighted-line-gutter",
          },
        },
      ];

      // Apply the new decoration and store its ID
      decorationRef.current = editorRef.current.deltaDecorations([], newDecorations);
    }
  };

  const animateHintInsertion = async () => {
    if (!editorRef.current || !monaco) return;

    const editor = editorRef.current;
    const originalLines = originalCode.trim().split("\n");
    const hintedLines = hintedCode.trim().split("\n");

    let originalPointer = 0;
    let hintedPointer = 0;

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Initially, mark all lines as unwatched
    const totalLines = originalLines.length;
    const grayLines = Array.from({ length: totalLines }, (_, i) => i);
    updateLineColors(grayLines);

    while (originalPointer < originalLines.length || hintedPointer < hintedLines.length) {
      const originalLine = originalLines[originalPointer] || "";
      const hintedLine = hintedLines[hintedPointer] || "";

      // Highlight current line
      setCurrentHighlightedLine(hintedPointer);
      updateLineHighlight(hintedPointer);

      if (originalLine === hintedLine) {
        await delay(200); // Pause to show highlight

        // Mark line as watched by removing it from grayLines
        const updatedGrayLines = grayLines.filter((i) => i !== originalPointer);
        updateLineColors(updatedGrayLines);

        originalPointer++;
        hintedPointer++;
      } else {
        const words = hintedLine.split(" ");
        let animatedLine = "";

        for (let word of words) {
          animatedLine += word + " ";
          const updatedLines = [...originalLines];
          updatedLines.splice(hintedPointer, 0, animatedLine.trim());
          editor.setValue(updatedLines.join("\n"));

          await delay(50);
        }

        originalLines.splice(hintedPointer, 0, hintedLine);

        // After inserting the hinted line, mark it as watched
        const updatedGrayLines = grayLines.filter((i) => i !== hintedPointer);
        updateLineColors(updatedGrayLines);

        await delay(100);
        originalPointer++;
        hintedPointer++;
      }
    }

    // Clear highlight when animation is complete
    setCurrentHighlightedLine(null);
    updateLineHighlight(null);
  };

  return (
    <div className="editor-container">
      <Editor
        height="600px"
        defaultLanguage="javascript"
        defaultValue={originalCode.trim()}
        onMount={handleEditorDidMount}
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          scrollBeyondLastLine: false,
          fontSize: 14,
          lineHeight: 24,
          readOnly: false,
        }}
      />
      <button onClick={animateHintInsertion} className="animation-button">
        Start Animation
      </button>
      <style>
        {`
          .editor-container {
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }

          .highlighted-line {
            background-color: rgba(59, 130, 246, 0.2) !important;
            transition: background-color 0.3s ease;
          }

          .highlighted-line-gutter {
            border-left: 3px solid #3B82F6 !important;
            transition: border-left-color 0.3s ease;
          }

          .unwatched-line {
            color: gray !important;
          }

          .animation-button {
            margin-top: 16px;
            padding: 8px 16px;
            background-color: #3B82F6;
            color: white;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            transition: background-color 0.2s ease;
          }

          .animation-button:hover {
            background-color: #2563EB;
          }

          .animation-button:active {
            background-color: #1D4ED8;
          }
        `}
      </style>
    </div>
  );
};

export default MonacoEditorWithHints;
