// src/components/VideoInterface.jsx

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { getProctoringResult, saveProctoringResults, uploadMediaChunk, } from '../../services/api'; // Ensure this points to the correct upload endpoint
import { compressImage, sendCompressedImage } from '../../utils/ImageTools';
import { convertBlobToBase64 } from '../../utils/base64';
import { set } from 'date-fns';
import LoadingScreen from '../../utils/LoadingPage';
import { UploadVideoData } from '../../services/api2'

const VideoInterface = ({
  isAISpeaking,
  timer,
  onEndInterview,
  interviewId,
  email,
  audioHandlerRef,
  properTimer,
  setVideoUploadingStatus,
  videoUploadingStatus,
  screen
}) => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null); // Using useRef instead of useState
  const [cameraError, setCameraError] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const recordedChunksRef = useRef([]); // Using ref to prevent re-renders
  const [error, setError] = useState(false); // To track upload errors
  const canvasRef = useRef(null); // Ref for the canvas used in image capture
  const [proctoringResults, setProctoringResults] = useState([])
  const mediaStreamRef = useRef(null)

  /**
   * handleDataAvailable is called when a video chunk is available.
   * It stores the chunk in the recordedChunksRef.
   */


  /**
   * handleMediaRecorderError handles errors from the MediaRecorder.
   */


  /**
   * uploadRecordedVideo uploads the recorded video blob to the server.
   */
  const uploadRecordedVideo = async (blob) => {
    const formData = new FormData();
    formData.append('interviewId', interviewId);
    formData.append('email', email);
    formData.append('containerName', 'videos');
    formData.append('chunk', blob);
    if (interviewId && email) {
      let response = await UploadVideoData(formData)
      console.log(response);

    }
  };

  /**
   * startMediaStream initializes the media stream and starts recording.
   */
  const startMediaStream = async () => {
    try {
      // Request access to camera and microphone
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      mediaStreamRef.current = stream;

      // Set the video element's source to the stream
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      // Initialize MediaRecorder with desired options
      let options = { mimeType: 'video/webm; codecs=vp9,opus' };
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {
        console.warn(`${options.mimeType} is not supported, trying a different codec.`);
        options = { mimeType: 'video/webm; codecs=vp8,opus' };
        if (!MediaRecorder.isTypeSupported(options.mimeType)) {
          console.warn(`${options.mimeType} is not supported, trying default codecs.`);
          options = {};
        }
      }

      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorderRef.current = mediaRecorder;

      // Handle dataavailable event to capture recorded data
      mediaRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {

          uploadRecordedVideo(event.data);

        }
      };

      // Handle recorder errors
      mediaRecorder.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        setError('MediaRecorder error: ' + event.error.message);
      };

    } catch (err) {
      console.error('Error accessing media devices.', err);
      setError('Error accessing media devices: ' + err.message);
    }
  };

  /**
   * stopRecording stops the MediaRecorder and triggers end interview.
   */
  const stopRecording = async () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
        console.log(`Stopped track: ${track.kind}`);
      });
      mediaStreamRef.current = null; // Clear the reference
    }

    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      console.log('Stopped MediaRecorder.');
    }

    setIsRecording(false); // Update recording state
    await onEndInterview();
  }
  const startRecording = async () => {

    if (isRecording) {
      console.warn('Recording is already in progress.');
      return;
    }
    // Initialize media and recorder if not already initialized
    if (!mediaStreamRef.current) {
      await startMediaStream();
      if (!mediaStreamRef.current) {
        // Initialization failed
        return;
      }
    }

    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      setError('MediaRecorder is not initialized.');
      return;
    }

    try {
      mediaRecorder.start(5000); // Start recording with a 5-second timeslice
      setIsRecording(true);
      console.log('MediaRecorder started:', mediaRecorder);
    } catch (startError) {
      console.error('Error starting MediaRecorder:', startError);
      setError('Error starting MediaRecorder: ' + startError.message);
    }
  }
  useEffect(() => {
    if (screen == 'interview') {
      startRecording()

    }
  }, [screen])
  /**
   * Handle end of interview based on timer
   */
  useEffect(() => {
    if (timer <= 0 && isRecording) {
      console.log('VideoInterface: Timer expired. Stopping recording.');
      stopRecording();
    }
  }, [timer, isRecording, stopRecording]);

  /**
   * useEffect to start media stream on component mount.
   */
  useEffect(() => {


    // Cleanup on component unmount

  }, []); // Removed 'stream' from dependencies

  /**
   * captureImage captures a single frame from the video stream.
   */
  useEffect(() => {
    if (properTimer % 6 == 0) {
      // handleCaptureAndSend()
    }
  }, [properTimer])

  /**
   * compressImage compresses the image blob to the specified quality.
   * @param {Blob} imageBlob - The image blob to compress.
   * @param {number} quality - Compression quality (0 to 1).
   * @returns {Promise<Blob>} - The compressed image blob.
   */


  /**
   * sendImage sends the compressed image blob to the backend.
   * @param {Blob} compressedBlob - The compressed image blob.
   */


  /**
   * handleCaptureAndSend orchestrates the capture, compress, and send process.
   */
  useEffect(() => {
    if (proctoringResults.length >= 5) {
      saveProctoringResults(interviewId, email, proctoringResults)
      setProctoringResults([])
    }
  }, [proctoringResults])
  const handleCaptureAndSend = async () => {
    if (!videoRef.current) {
      console.error('VideoInterface: Video reference is not available.');
      return;
    }

    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('VideoInterface: Canvas reference is not available.');
      return;
    }

    const context = canvas.getContext('2d');
    const width = video.videoWidth;
    const height = video.videoHeight;

    canvas.width = width;
    canvas.height = height;

    // Draw the current frame from the video onto the canvas
    context.drawImage(video, 0, 0, width, height);

    // Get the image blob
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error('VideoInterface: Failed to capture image.');
        setError(true);
        return;
      }



      try {
        // Compress the image
        const compressedBlob = await compressImage(blob, 0.2); // 20% quality


        // Log the image data URL to the console
        const reader = new FileReader();
        reader.onloadend = () => {

        };
        reader.readAsDataURL(compressedBlob);
        let timeStamp = new Date().toISOString().split('T')[1].slice(0, -1)
        let result = await getProctoringResult(compressedBlob);
        let base64 = await convertBlobToBase64(compressedBlob)
        let dataToSend = {
          timeStamp: timeStamp,
          image: base64,
          result: result
        }
        setProctoringResults([...proctoringResults, dataToSend])
        // Send the compressed image to the backend

      } catch (err) {
        console.error('VideoInterface: Error during image processing:', err);
        // setError(true);
      }
    }, 'image/jpeg');
  };

  /**
   * Handle end of interview based on browser unload
   */


  return (
    <div className="fixed inset-0 bg-gray-900 overflow-hidden">
      {/* Main video container - Your Camera */}
      {videoUploadingStatus == "uploading" && <LoadingScreen message='Saving the interview..' />}
      <div
        className={`relative w-full h-[calc(100%-64px)] ${!isAISpeaking ? 'ring-2 ring-blue-500' : ''
          }`}
      >
        {cameraError ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-800">
            <div className="text-center text-white">
              <svg
                className="w-16 h-16 mx-auto mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                />
              </svg>
              <p className="text-lg font-medium">Camera access required</p>
              <p className="text-sm text-gray-400 mt-2">
                Please enable camera access to continue
              </p>
            </div>
          </div>
        ) : (
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            autoPlay
            playsInline
            muted
          // Removed redundant onLoadedMetadata
          />
        )}

        {/* Hidden Canvas for Image Capture */}
        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

        {/* User label */}
        <div className="absolute bottom-6 left-6 bg-black bg-opacity-50 backdrop-blur-sm px-4 py-2 rounded-lg">
          <span className="text-white text-sm font-medium">You</span>
        </div>
      </div>

      {/* Picture-in-picture - AI Interviewer */}
      <div
        className={`absolute bottom-20 right-6 w-64 h-44 bg-gray-800 rounded-lg overflow-hidden shadow-lg ${isAISpeaking ? 'ring-2 ring-blue-500' : ''
          }`}
      >
        <div className="relative w-full h-full">
          {/* AI Interviewer display */}
          <div className="w-full h-full bg-gradient-to-b from-gray-700 to-gray-900 flex items-center justify-center">
            <div className="text-center">
              <div className="w-16 h-16 mx-auto mb-2 rounded-full bg-blue-500 bg-opacity-20 flex items-center justify-center">
                <svg
                  className="w-8 h-8 text-blue-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <span className="text-white text-sm font-medium">
                Easecruit Interviewer
              </span>
            </div>
          </div>

          {/* AI Status Indicator */}
          {isAISpeaking && (
            <div className="absolute top-3 right-3 flex items-center space-x-1.5 bg-blue-500 bg-opacity-20 backdrop-blur-sm px-3 py-1 rounded-full">
              <div className="w-2 h-2 rounded-full bg-blue-400 animate-pulse"></div>
              <span className="text-blue-100 text-xs font-medium">
                AI Active
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Control bar */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gray-900 bg-opacity-95 backdrop-blur-sm border-t border-gray-800">
        <div className="h-full max-w-screen-xl mx-auto px-6 flex items-center justify-between">
          {/* Time and interview info */}
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse"></div>
              <span className="text-white text-sm font-medium">{timer}</span>
            </div>
            <span className="text-gray-400">|</span>
            <span className="text-white text-sm">Technical Interview</span>
          </div>

          {/* Controls */}
          <div className="flex items-center space-x-2">
            {/* Capture Image Button */}


            {/* End call button */}
            <button
              className={`p-3 rounded-lg bg-red-500 hover:bg-red-600 text-white transition-colors ${!isRecording ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              onClick={stopRecording}
              disabled={!isRecording}
              aria-label="End Interview"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Optional: Error Message */}


      {/* Optional: Indicators for Recording */}
      {isRecording && (
        <div className="absolute top-4 left-4 bg-red-500 text-white px-3 py-1 rounded-full">
          Recording...
        </div>
      )}
    </div>
  );
};

export default VideoInterface;
