// src/AudioHandler.js

import axios from 'axios';
import { uploadMediaChunk } from './services/api';

export default class AudioHandler {
  constructor() {
    this.context = new (window.AudioContext || window.webkitAudioContext)({
      sampleRate: 24000, // Ensure this matches your audio data's sample rate
    });
    this.workletNode = null;
    this.stream = null;
    this.source = null;
    this.sampleRate = 24000;

    this.isPlaying = false;
    this.isAIPlaying = false;
    // Update playbackQueue to hold objects with both buffer and original audioChunk
    this.playbackQueue = [];

    // Destination for recording incoming audio
    this.destination = this.context.createMediaStreamDestination();

    // Playback management
    this.playbackSource = null;

    // MediaRecorder for recording audio
    this.mediaRecorder = null;
    this.recordedAudioChunks = [];

    // Ensure that interviewId and email are set externally
    this.interviewId = null;
    this.email = null;
  }

  /**
   * Initializes the AudioWorklet and starts recording.
   */
  async initialize() {
    try {
      await this.context.audioWorklet.addModule('/audio-processor.js');
      console.log('AudioHandler: AudioWorklet module loaded.');
      await this.startRecording();
      this.initializeMediaRecorder(); // Initialize MediaRecorder after starting recording
    } catch (error) {
      console.error('AudioHandler: Error loading AudioWorklet module:', error);
      throw error; // Rethrow to handle it in the calling function
    }
  }

  /**
   * Initializes the MediaRecorder to record incoming audio.
   */
  initializeMediaRecorder() {
    if (!this.destination.stream) {
      console.error('AudioHandler: No destination stream available for MediaRecorder.');
      return;
    }

    const mimeType = 'audio/webm; codecs=opus';
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      console.error(`AudioHandler: MIME type ${mimeType} is not supported.`);
      return;
    }

    this.mediaRecorder = new MediaRecorder(this.destination.stream, { mimeType });

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data && event.data.size > 0) {
        this.recordedAudioChunks.push(event.data);
        console.log(`AudioHandler: Audio chunk received. Total chunks: ${this.recordedAudioChunks.length}`);
      }
    };

    this.mediaRecorder.onstart = () => {
      console.log('AudioHandler: MediaRecorder started recording.');
    };

    this.mediaRecorder.onstop = () => {
      console.log('AudioHandler: MediaRecorder stopped recording.');

    };

    this.mediaRecorder.onerror = (error) => {
      console.error('AudioHandler: MediaRecorder error:', error);
    };
  }

  /**
   * Starts recording incoming audio.
   */
  async startRecording() {
    try {
      console.log('AudioHandler: Requesting user media for audio...');
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: this.sampleRate,
          echoCancellation: true,
          noiseSuppression: true,
        },
      });
      console.log('AudioHandler: User media for audio obtained.');

      await this.context.resume();
      this.source = this.context.createMediaStreamSource(this.stream);
      this.workletNode = new AudioWorkletNode(
        this.context,
        'audio-recorder-processor'
      );

      // Handle messages from the AudioWorklet
      this.workletNode.port.onmessage = (event) => {
        if (event.data.eventType === 'audio') {
          console.log('AudioHandler: Received audio data from AudioWorklet.');
          // Here, you can process the audio data if needed
          // For now, we're just logging it
        }
      };

      // Connect the nodes
      this.source.connect(this.workletNode);
      this.workletNode.connect(this.context.destination); // For playback if needed
      this.workletNode.connect(this.destination); // For recording

      // Start recording in the AudioWorklet
      this.workletNode.port.postMessage({ command: 'START_RECORDING' });
      console.log('AudioHandler: Started recording in AudioWorklet.');

      // Start MediaRecorder
      if (this.mediaRecorder) {
        this.mediaRecorder.start();
        console.log('AudioHandler: MediaRecorder started.');
      }
    } catch (error) {
      console.error('AudioHandler: Error starting recording:', error);
      throw error;
    }
  }

  /**
   * Triggers saving the recorded audio by stopping the MediaRecorder.
   */
  async triggerSaveRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop(); // This will automatically call saveRecording via onstop
      console.log('AudioHandler: MediaRecorder stopped for saving recording.');
    } else {
      console.warn('AudioHandler: MediaRecorder is not active.');
    }
  }

  /**
   * Saves the recorded audio to the backend.
   */


  /**
   * Plays an incoming audio chunk and uploads it separately with a timestamp.
   * @param {Uint8Array} audioChunk - The audio data chunk as Uint8Array.
   */
  playChunk(audioChunk) {
    if (!audioChunk || audioChunk.length === 0) {
      console.warn('AudioHandler: Received empty audio chunk.');
      return;
    }
    this.isAIPlaying = true;
    // Convert Uint8Array to Int16Array (assuming little-endian)
    const int16Data = new Int16Array(audioChunk.buffer);

    // Convert Int16 samples to Float32 samples
    const float32Data = new Float32Array(int16Data.length);
    for (let i = 0; i < int16Data.length; i++) {
      float32Data[i] = int16Data[i] / 32768; // Normalize to [-1, 1]
    }

    // Create an AudioBuffer
    const audioBuffer = this.context.createBuffer(
      1, // number of channels
      float32Data.length,
      this.sampleRate
    );

    // Copy the Float32 data into the AudioBuffer
    audioBuffer.copyToChannel(float32Data, 0);

    // Add the buffer and original audioChunk to the playback queue
    this.playbackQueue.push({ buffer: audioBuffer, audioChunk, float32Data });

    // Process the queue
    this.processQueue();
  }

  /**
   * Processes the playback queue to play audio chunks sequentially and upload them.
   */
  async processQueue() {
    if (this.isPlaying || this.playbackQueue.length === 0) return;
    this.isAIPlaying = true
    this.isPlaying = true;
    const { buffer, audioChunk, float32Data } = this.playbackQueue.shift();

    // Upload the audio chunk with a timestamp marker
    const timestamp = Date.now();
    try {
      // this.uploadAudioChunk(float32Data, timestamp);

    } catch (error) {
      console.error('AudioHandler: Failed to upload audio chunk:', error);
      // Optionally, handle retry logic or queue the chunk for later upload
    }

    // Play the audio buffer
    this.playbackSource = this.context.createBufferSource();
    this.playbackSource.buffer = buffer;
    this.playbackSource.connect(this.context.destination);
    this.playbackSource.start();

    this.playbackSource.onended = () => {
      if (this.playbackQueue.length === 0) {
        this.isAIPlaying = false
      }
      this.isPlaying = false;
      this.processQueue();
    };
  }

  /**
   * Uploads an individual audio chunk to the backend with a timestamp marker.
   * @param {Uint8Array} audioChunk - The audio data chunk as Uint8Array.
   * @param {number} timestamp - The timestamp marker in milliseconds.
   */
  async uploadAudioChunk(audioChunk, timestamp) {
    if (!this.interviewId || !this.email) {
      console.error('AudioHandler: interviewId and email must be set before uploading audio chunks.');
      return;
    }

    const blob = new Blob([audioChunk], { type: 'audio/webm' }); // Adjust MIME type if necessary

    const formData = new FormData();
    formData.append('interviewId', this.interviewId);
    formData.append('email', this.email);
    formData.append('timestamp', timestamp);
    formData.append('audioChunk', blob, `interview-${this.interviewId}-${timestamp}.webm`);

    try {
      let result = await uploadMediaChunk('audio', formData);

      // Optionally, you can handle post-upload logic here
    } catch (err) {
      console.error('AudioHandler: Error uploading audio chunk:', err);
      // Optionally, implement retry logic or notify the user
      throw err; // Rethrow to handle it in the calling function if needed
    }
  }

  /**
   * Starts streaming playback. (Placeholder for future enhancements)
   */
  startStreamingPlayback() {
    // If there's any initialization needed before streaming playback,
    // implement it here. Currently, playback is handled via playChunk.
    console.log('AudioHandler: Streaming playback started.');
  }

  /**
   * Stops streaming playback by clearing the playback queue and stopping current playback.
   */
  stopStreamingPlayback() {
    console.log('AudioHandler: Streaming playback stopped.');
    // Stop current playback
    if (this.playbackSource) {
      try {
        this.playbackSource.stop();
      } catch (error) {
        console.warn('AudioHandler: Attempted to stop already stopped playback source.');
      }
      this.playbackSource.disconnect();
      this.playbackSource = null;
    }
    this.isPlaying = false;
    // Clear the playback queue
    this.playbackQueue = [];
  }

  /**
   * Closes the AudioContext and stops all audio processing.
   */
  async close() {
    try {
      if (this.context.state !== 'closed') {
        if (this.workletNode) {
          this.workletNode.port.postMessage({ command: 'STOP_RECORDING' });
          this.workletNode.disconnect();
          console.log('AudioHandler: WorkletNode disconnected.');
        }
        if (this.source) {
          this.source.disconnect();
          console.log('AudioHandler: Audio source disconnected.');
        }
        if (this.stream) {
          this.stream.getTracks().forEach((track) => track.stop());
          console.log('AudioHandler: Audio tracks stopped.');
        }
        if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
          this.mediaRecorder.stop();
          console.log('AudioHandler: MediaRecorder stopped.');
        }

        await this.context.close();
        console.log('AudioHandler: AudioContext closed successfully.');
      } else {
        console.warn('AudioHandler: Attempted to close an already closed AudioContext.');
      }
    } catch (error) {
      console.error('AudioHandler: Error closing AudioContext:', error);
    }
  }
}
