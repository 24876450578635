import { arrayBufferToBase64, bufferToPCM16 } from "../../utils/base64";

const SendTextMessageToWebSocket = (socketRef, text, event_id = null) => {
    let message = {
        "type": "conversation.item.create",
        "item": {
            "type": "message",
            "role": "user",
            "content": [
                {
                    "type": "input_text",
                    "text": text
                }
            ]
        }
    }
    socketRef.current.sendMessage(JSON.stringify(message));
}
const sendPreRecordedAudioToWebSocket = async (sendAudioChunk, audio) => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const response = await fetch(audio);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const arrayBuffer = await response.arrayBuffer();

    // Decode the audio data
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const decodedData = await audioContext.decodeAudioData(arrayBuffer);

    // Create an OfflineAudioContext for processing
    const offlineContext = new OfflineAudioContext(
        1, // mono
        Math.ceil(decodedData.duration * 24000), // sampleRate = 24000 Hz
        24000
    );

    // Create a buffer source
    const bufferSource = offlineContext.createBufferSource();
    bufferSource.buffer = decodedData;
    bufferSource.connect(offlineContext.destination);
    bufferSource.start(0);

    // Render the audio
    const renderedBuffer = await offlineContext.startRendering();

    // Get PCM16 data
    const pcm16Data = bufferToPCM16(renderedBuffer.getChannelData(0));

    // Convert PCM16 data to ArrayBuffer
    const pcm16ArrayBuffer = pcm16Data.buffer;

    // Chunking parameters
    const chunkSize = 16000; // 16KB per chunk; adjust as needed
    const totalChunks = Math.ceil(pcm16ArrayBuffer.byteLength / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
        const chunk = pcm16ArrayBuffer.slice(i * chunkSize, (i + 1) * chunkSize);
        const base64 = arrayBufferToBase64(chunk);
        sendAudioChunk(base64);
        await delay(100); // 100ms delay between chunks; adjust as needed
    }

    console.log('Finished sending sample audio');
}
export { SendTextMessageToWebSocket, sendPreRecordedAudioToWebSocket }