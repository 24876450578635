// src/services/api.js

import axios from 'axios';

/**
 * Uploads a media chunk to the server.
 * @param {string} mediaType - The type of media ('video', 'human-audio', 'ai-audio').
 * @param {FormData} formData - The form data containing the media chunk.
 * @returns {Promise<object>} - The server response.
 */
let port = `https://realtimeapi.easecruit.com`

export const FetchInterviewCredentials=async (interviewId)=>
{
    const response=await axios.get(`${port}/interview/interviews/${interviewId}/credentials`)
    return response.data;
}
export const uploadMediaChunk = async (mediaType, formData) => {
  const response = await axios.post(`${port}/media/upload-${mediaType}-chunk`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};


/**
 * Fetches video chunk URLs for playback.
 * @param {string} interviewId - The interview ID.
 * @param {string} email - The user's email.
 * @param {string} mediaType - The media type ('video', 'human-audio', 'ai-audio').
 * @returns {Promise<object>} - The server response containing chunk URLs.
 */
export const getVideoChunks = async (videoUrl) => {
  try {
    // Encode the videoUrl to safely include it as a query parameter
    const encodedVideoUrl = encodeURIComponent(videoUrl);
    const proxiedVideoUrl = `${port}/video/video?videoUrl=${encodedVideoUrl}`; // Assuming the backend proxy is at /video

    return { proxiedVideoUrl };
  } catch (error) {
    console.error('Error getting video chunks:', error);
    throw error;
  }
};
export const getVideoUrl = async (interviewId, email, mediaType) => {
  const response = await axios.post(`${port}/media/get-video-chunks`, {
    interviewId,
    email,
    mediaType,
  });
  return response.data;
};
/**
 * Fetches a specific video chunk via the backend.
 * @param {string} interviewId - The interview ID.
 * @param {string} email - The user's email.
 * @param {string} mediaType - The media type ('video', 'human-audio', 'ai-audio').
 * @param {string} timestamp - The timestamp used to identify the chunk.
 * @returns {Promise<ArrayBuffer>} - The blob data of the chunk.
 */
export const serveVideoChunk = async (chunkUrls) => {
  const response = await axios.post(`${port}/media/serve-video-chunk`, {
    chunkUrls
  },
    {
      responseType: 'blob',
    });
  return response.data;
};


export const UploadAIAudio = async (interviewId, email, audioChunks) => {
  const response = await axios.post(`${port}/ai-audio/add-audio`, {
    interviewId,
    email,
    newAudioObject: audioChunks,
  });
  return response.data;
}

export const FetchAIAudios = async (email, interviewId) => {
  const response = await axios.get(`${port}/ai-audio/get-audio`, {
    params: {
      email: email,
      interviewId: interviewId
    }
  });
  return response.data;
}

export const createAIInterviewRecordingEntry = async (email, interviewId) => {
  const response = await axios.post(`${port}/ai-audio/create-entry`, {
    email: email,
    interviewId: interviewId,
    createdAt: new Date().toISOString().split('T')[1].slice(0, -1)
  });
  return response.data;
}

export const createAIInterviewRecordingEndEntry = async (interviewId, email) => {
  const response = await axios.post(`${port}/ai-audio/create-end-entry`, {
    email: email,
    interviewId: interviewId,
    EndAt: new Date().toISOString().split('T')[1].slice(0, -1)
  });
  return response.data;
}
export const getProctoringResult = async (image) => {
  try {
    // Convert image to Base64 string if it is not already
    const base64Image = typeof image === "string" ? image : await convertToBase64(image);

    const response = await axios.post(`${port}/proctoring/proctor`, {
      Image: base64Image,
    });

    return response.data;
  } catch (error) {
    console.error("Error sending proctoring result:", error);
    throw error;
  }
};

export const getInterviewAnalysis = async (interviewId, email) => {
  let response = await axios.post(`${port}/interview/analyze`, {
    interviewId,
    email
  })
  return response.data;
}
export const getInterviewEmails = async (interviewId) => {
  let response = await axios.get(`${port}/interview/interviews/${interviewId}/emails`)
  return response.data;
}

export const saveProctoringResults = async (interviewId, emailId, proctoringResults) => {
  const response = await axios.put(`${port}/proctoring/append`, {
    interviewId,
    emailId,
    Images: proctoringResults,
  });
  return response.data;
}

export const fetchProctoringResults = async (interviewId, emailId) => {
  const response = await axios.post(`${port}/proctoring/images`, {
    interviewId,
    emailId
  });
  return response.data;
}
// Helper function to convert an image file to a Base64 string
const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]); // Remove data URL prefix
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

export const getConversations = async (interviewId, email) => {
  const response = await axios.get(`${port}/interview/conversation/${interviewId}/${email}`);
  return response.data;
}

export const FunctionCall = async (userPrompt) => {
  const response = await axios.post('http://localhost:5004/llm/function_call',
    {
      userPrompt
    }
  )
  return response.data;
}

export const AskHintInCode = async (problemStatement, code, language) => {
  const response = await axios.post(`http://localhost:5004/llm/provide_hint`, {
    problemStatement,
    code,
    language
  })
  return response.data;
}

export const GenerateTestCases = async (problemStatement) => {
  const response = await axios.post(`http://localhost:5004/llm/generate_test_cases`, {
    problemStatement
  })
  return response.data;
}