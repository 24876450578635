import React from 'react';

const LoadingScreen = ({ message = "Loading..." }) => {
  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="flex flex-col items-center space-y-6 p-8 max-w-sm mx-auto text-center">
        {/* Loading Animation */}
        <div className="relative">
          {/* Outer Circle */}
          <div className="w-16 h-16 rounded-full border-4 border-gray-200"></div>

          {/* Spinning Gradient Circle */}
          <div className="absolute top-0 left-0 w-16 h-16">
            <div className="w-16 h-16 rounded-full border-4 border-transparent border-t-[var(--dark-color)] animate-loading-spin"></div>
          </div>

          {/* Center Dot */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-2 h-2 rounded-full bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)]"></div>
          </div>
        </div>

        {/* Message */}
        <div className="space-y-2">
          <p className="text-lg font-medium text-gray-900">{message}</p>
          <div className="flex justify-center items-center gap-1">
            <div className="w-1.5 h-1.5 rounded-full bg-[var(--dark-color)] animate-loading-dot-1"></div>
            <div className="w-1.5 h-1.5 rounded-full bg-[var(--dark-color)] animate-loading-dot-2"></div>
            <div className="w-1.5 h-1.5 rounded-full bg-[var(--dark-color)] animate-loading-dot-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;