// src/services/WebSocketService.js
import { base64ToUint8Array } from '../utils/base64';
import { Buffer } from 'buffer';
export default class WebSocketService {
  constructor(url, onMessage, onOpen, onClose, onError) {
    this.socket = new WebSocket(url);
    this.socket.onmessage = this.handleMessage.bind(this, onMessage);
    this.socket.onopen = onOpen;
    this.socket.onclose = onClose;
    this.socket.onerror = onError;
  }

  handleMessage(callback, event) {
    try {
      const messageStr = Buffer.from(event.data, 'base64').toString('utf-8');
      callback(messageStr);
    } catch (error) {
      console.error('Error handling message:', error);
    }
  }

  sendMessage(message) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open. Ready state:', this.socket.readyState);
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }
}
