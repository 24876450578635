import React, { useState } from 'react';
import { Zap, Users, Brain, ChevronRight, Menu, X } from 'lucide-react';
import DemoVideo from './DemoVideo';
import DemoAnalysis from './DemoAnalysis';

export default function AIInterviewDemo() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
            {/* Navbar */}


            {/* Main Content with proper spacing from fixed navbar */}
            <main className="pt-24"> {/* Added pt-24 to account for fixed navbar */}
                {/* Hero Section */}
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <section className="text-center mb-20">
                        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                            Experience the Future of
                            <span className="text-[var(--dark-color)] block mt-2">Technical Interviews</span>
                        </h1>
                        <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-8">
                            Revolutionize your hiring process with our AI-powered interview platform that combines
                            intelligence, accuracy, and efficiency.
                        </p>
                        <div className="flex justify-center gap-4">

                            <div className="hidden md:block">
                                <DemoVideo />
                            </div>
                            {/* Mobile: Show Watch Demo Button */}
                            <div className="block md:hidden">
                                <button
                                    className="border-2 border-[var(--dark-color)] text-[var(--dark-color)] font-semibold px-8 py-3 rounded-xl hover:bg-[var(--dark-color)]/5 transition-all duration-300"
                                    onClick={() => window.open("https://drive.google.com/file/d/1AvbTt9kz4_Tlx0u-Z9Hpv4J6tSNs6nSp/view?usp=drive_link", "_blank")}
                                >
                                    Watch Demo
                                </button>
                            </div>
                        </div>
                    </section>

                    {/* Features Section */}
                    <section className="mb-20">
                        <div className="text-center mb-12">
                            <h2 className="text-5xl font-bold text-gray-900 mb-4">Interview Analysis</h2>
                        </div>
                    </section>
                    <section>
                        <DemoAnalysis />
                    </section>
                    {/* Process Section */}
                    <section className="mb-20">
                        <div className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-900 mb-4">The Interview Process</h2>
                            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                                A streamlined approach to technical assessment
                            </p>
                        </div>

                        <div className="grid md:grid-cols-3 gap-8">
                            {[1, 2, 3].map((step) => (
                                <div
                                    key={step}
                                    className="relative bg-white p-8 rounded-2xl border-2 border-[var(--dark-color)]/10 hover:border-[var(--dark-color)] group"
                                >
                                    <div className="absolute -top-4 -left-4 w-8 h-8 rounded-full bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white flex items-center justify-center font-bold">
                                        {step}
                                    </div>
                                    <h3 className="text-xl font-semibold text-gray-900 mb-3 pt-2">
                                        {step === 1 ? "Initial Assessment" : step === 2 ? "Technical Deep Dive" : "Final Evaluation"}
                                    </h3>
                                    <p className="text-gray-600">
                                        {step === 1
                                            ? "Basic technical knowledge and problem-solving capabilities evaluation."
                                            : step === 2
                                                ? "In-depth technical questions and real-world scenario analysis."
                                                : "Comprehensive review of all responses and detailed scoring."}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* CTA Section */}
                    <section className="text-center bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] rounded-2xl p-12 shadow-xl mb-20">
                        <h2 className="text-3xl font-bold text-white mb-4">Ready to Transform Your Hiring?</h2>
                        <p className="text-xl text-white/90 mb-8 max-w-2xl mx-auto">
                            Join leading companies using Easecruit AI to find the best technical talent.
                        </p>
                        <button className="bg-white text-[var(--dark-color)] font-bold py-3 px-8 rounded-xl hover:shadow-lg transition-all duration-300 hover:bg-gray-50" onClick={() => window.location.href = "https://calendly.com/easecruit/30min?month=2024-12"}>
                            Schedule a Demo
                        </button>
                    </section>
                </div>
            </main>
        </div>
    );
}
