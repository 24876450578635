import React, { useState, useMemo, useEffect } from 'react';
import { FaEnvelope, FaChartLine, FaSearch, FaExclamationTriangle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { getInterviewEmails } from '../../services/api';

const EmailListPage = () => {
  const [emails, setEmails] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [interviewId, setInterviewId] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const interviewId = searchParams.get('interviewId')
    setInterviewId(interviewId)
  }, [])
  useEffect(() => {
    const FetchEmails = async () => {
      try {
        let response = await getInterviewEmails(interviewId)
        console.log(response);
        if (response.success) {
          setEmails(response.emails)
        }
      }
      catch (err) {
        console.error("Error fetching emails:", err)
      }
    }
    if (interviewId) FetchEmails()
  }, [interviewId])
  // Sort and filter emails
  const sortedAndFilteredEmails = useMemo(() => {
    return emails
      ?.filter(email =>
        email?.email?.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        // Sort by score (null values at the bottom)
        if (a.score === null && b.score === null) return 0;
        if (a.score === null) return 1;
        if (b.score === null) return -1;
        return b.score - a.score;
      }) || [];
  }, [emails, searchTerm]);

  // Pagination
  const totalPages = Math.ceil(sortedAndFilteredEmails.length / itemsPerPage);
  const currentEmails = sortedAndFilteredEmails.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleViewAnalysis = (email) => {
    // Handle navigation to analysis page
    let url=`/interview/analysis?interviewId=${encodeURIComponent(interviewId)}&email=${encodeURIComponent(email)}`
    navigate(url,"_blank")
  };

  return (
    <div className="min-h-screen bg-background">
      {/* Header */}
      <div className="bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white">
        <div className="container mx-auto px-4 sm:px-6 py-8 sm:py-12">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div>
              <h1 className="text-2xl sm:text-3xl font-bold mb-2">Interview Results</h1>
              <p className="text-white/80">
                Total Interviews: {sortedAndFilteredEmails.length}
              </p>
            </div>
            {/* Search Bar */}
            <div className="w-full md:w-auto">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search by email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full md:w-80 px-4 py-2 pl-10 rounded-lg bg-white/10 border border-white/20 text-white placeholder-white/60 focus:outline-none focus:ring-2 focus:ring-white/30"
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/60" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 sm:px-6 py-8">
        {/* Email List */}
        <div className="space-y-4">
          {currentEmails.length > 0 ? (
            currentEmails.map((item, index) => (
              <div
                key={index}
                className="bg-card rounded-xl border border-[var(--border-color)] shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className="p-6 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-[var(--dark-color)]/10 rounded-xl text-[var(--dark-color)]">
                      <FaEnvelope />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">{item.email || 'No Email'}</h3>
                      <div className="flex items-center gap-2 mt-1">
                        <FaChartLine className="text-[var(--dark-color)]" />
                        <span className={`text-sm ${item.score === null
                          ? 'text-gray-500'
                          : item.score >= 70
                            ? 'text-[var(--dark-color)]'
                            : 'text-gray-700'
                          }`}>
                          {item.score === null ? 'Pending Score' : `Score: ${item.score}%`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => handleViewAnalysis(item.email)}
                    className="px-6 py-2 rounded-lg bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white hover:opacity-90 transition-all duration-300"
                  >
                    View Analysis
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center py-12">
              <FaExclamationTriangle className="mx-auto text-4xl text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900">No Results Found</h3>
              <p className="text-gray-500 mt-2">Try adjusting your search criteria</p>
            </div>
          )}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="mt-8 flex justify-center gap-2">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`px-4 py-2 rounded-lg transition-all duration-300 ${currentPage === page
                  ? 'bg-gradient-to-r from-[var(--dark-color)] to-[var(--moredark-color)] text-white'
                  : 'border border-[var(--border-color)] hover:bg-gray-50'
                  }`}
              >
                {page}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailListPage;