// src/components/InterviewInterface/InterviewInterface.js

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AudioHandler from '../../AudioHandler';
import WebSocketService from '../../services/WebSocketService';
import { base64ToUint8Array, bufferToPCM16 } from '../../utils/base64';
import startInterviewMp3 from '../../assets/start-interview.mp3';
import InterviewStartPage from '../StartPage/StartPage';
import RecordRTC from 'recordrtc';
import VideoInterface from './InterviewScreen'; // Assuming InterviewScreen.js exports VideoInterface
import { uploadMediaChunk, getMediaSasUrl, UploadAIAudio, createAIInterviewRecordingEndEntry, getInterviewAnalysis, FetchInterviewCredentials } from '../../services/api';
import { audio, use } from 'framer-motion/client';
import axios from 'axios'
import { CommitVideoBlob } from '../../services/api2';
function InterviewInterface() {
    const [isAIPlaying, setIsAIPlaying] = useState(false);
    const socketRef = useRef(null);
    const audioHandlerRef = useRef(null); // Single AudioHandler instance
    const responseDoneReceivedRef = useRef(false);
    const [email, setEmail] = useState(null);
    const [aiResponse, setAIResponse] = useState('');
    const [userResponse, setUserResponse] = useState('');
    const [systemPrompt, setSystemPrompt] = useState('');
    const [timer, setTimer] = useState(300); // Timer initialized to 300 seconds
    const [interviewId, setInterviewId] = useState(null)
    const [isConnected, setIsConnected] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [screen, setScreen] = useState("starting");
    const audioRecorder = useRef(null);
    const [videoBlockIds, setVideoBlockIds] = useState([]);
    const [humanAudioBlockIds, setHumanAudioBlockIds] = useState([]);
    const [aiAudioBlockIds, setAiAudioBlockIds] = useState([]);
    const navigate = useNavigate(); // Initialize useNavigate
    const [aiAudioChunkNumber, setAiAudioChunkNumber] = useState(1);
    const [chunkNumber, setChunkNumber] = useState(1);
    const [humanAudioChunkNumber, setHumanAudioChunkNumber] = useState(1);
    const location = useLocation()
    const [stream, setStream] = useState(null)
    const [aiAudioChunks, setAiAudioChunks] = useState([]);
    const [isChunkContinous, setIsChunkContinous] = useState(false);
    const [videoUploadingStatus, setVideoUploadingStatus] = useState('idle');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const interviewId = searchParams.get('id');
        const email = localStorage.getItem('email');
        if (interviewId && email) {
            setInterviewId(interviewId);
            setEmail(email);
        }
    }, [location]);

    useEffect(() => {
        if (interviewId) {
            // Reset chunk numbers
            setVideoBlockIds([]);
            setHumanAudioBlockIds([]);
            setAiAudioBlockIds([]);
            setAiAudioChunkNumber(1);
        }
    }, [interviewId]);

    useEffect(() => {
        if (interviewId) {
            let response = fetch(`https://realtimeapi.easecruit.com/interview/interviews/${interviewId}/credentials`);
            response.then((res) => res.json()).then((data) => {
                
                
                if (data.success) {
                    setSystemPrompt(data.credentials.system_prompt);
                    setTimer(data.credentials.minutes * 60);
                }
            })
        }
    }, [interviewId])

    /**
     * Ends the interview by performing cleanup and navigating to the Thank You screen.
     */
    useEffect(() => {
        if (videoUploadingStatus == "uploaded") {
            navigate('/thankyou');
        }
    }, [videoUploadingStatus])
    const EndInterview = async () => {
        console.log('Ending interview...');

        // Stop the timer
        setTimer(0);

        // Commit Video Chunks
        try {
            // await commitMediaChunks(interviewId, email, 'video');
            console.log('Video chunks committed successfully');
        } catch (error) {
            console.error('Error committing video chunks:', error);
        }

        // Commit Human Audio Chunks
        try {
            // await commitMediaChunks(interviewId, email, 'human-audio');
            console.log('Human audio chunks committed successfully');
        } catch (error) {
            console.error('Error committing human audio chunks:', error);
        }

        // Commit AI Audio Chunks
        try {
            // await commitMediaChunks(interviewId, email, 'ai-audio');
            console.log('AI audio chunks committed successfully');
        } catch (error) {
            console.error('Error committing AI audio chunks:', error);
        }

        // Close WebSocket connection
        if (socketRef.current) {
            socketRef.current.close();
            socketRef.current = null;
        }
        let promise1 = createAIInterviewRecordingEndEntry(interviewId, email);
        let promise2 = getInterviewAnalysis(interviewId, email);
        try {
            await CommitVideoBlob(interviewId, email);
        }
        catch (err) {

        }
        await Promise.all([promise1, promise2]);
        navigate('/thankyou');
        // Stop recording
        // stopRecording();

        // Close AudioHandler
        // if (audioHandlerRef.current) {
        //     await audioHandlerRef.current.close(); // Ensure async close
        //     audioHandlerRef.current = null;
        // }

        // Navigate to Thank You screen
        // navigate('/thankyou');
    };

    /**
     * Starts audio recording.
     */
    const startRecording = async () => {
        if (isRecording) return;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioRecorder.current = new RecordRTC(stream, {
                type: 'audio',
                mimeType: 'audio/wav',
                recorderType: RecordRTC.StereoAudioRecorder,
                numberOfAudioChannels: 1,
                timeSlice: 500,
                desiredSampRate: 24000,
                ondataavailable: (blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        const base64data = reader.result.split(',')[1];
                        sendAudioChunk(base64data);
                    };
                    reader.readAsDataURL(blob);
                },
            });

            audioRecorder.current.startRecording();
            setIsRecording(true);
            console.log('Recording started');
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    /**
     * Stops audio recording.
     */


    useEffect(() => {
        // Initialize AudioHandler
        audioHandlerRef.current = new AudioHandler();
        audioHandlerRef.current.interviewId = interviewId;
        audioHandlerRef.current.email = email;
        audioHandlerRef.current
            .initialize()
            .then(() => {
                console.log('AudioHandler initialized');
            })
            .catch((error) => {
                console.error('Error initializing AudioHandler:', error);;
            });

        // Initialize WebSocketService

        socketRef.current = new WebSocketService(
            `wss://wsinterview.easecruit.com`, // WebSocket URL
            processMessage,
            onSocketOpen,
            onSocketClose,
            onSocketError
        );

        // Handle AudioContext suspension on user interaction
        const resumeAudioContext = () => {
            if (
                audioHandlerRef.current.context &&
                audioHandlerRef.current.context.state === 'suspended'
            ) {
                audioHandlerRef.current.context.resume();
            }
        };

        window.addEventListener('click', resumeAudioContext);
        window.addEventListener('keydown', resumeAudioContext);

        return () => {
            // Clean up on unmount
            if (socketRef.current) {
                socketRef.current.close();
            }
            if (audioHandlerRef.current) {
                audioHandlerRef.current.close();
            }
            window.removeEventListener('click', resumeAudioContext);
            window.removeEventListener('keydown', resumeAudioContext);
        };
    }, [interviewId]);

    /**
     * WebSocket onOpen handler.
     */
    const onSocketOpen = () => {
        console.log('WebSocket connection opened');
        audioHandlerRef.current.startStreamingPlayback();
        setIsConnected(true);
    };

    /**
     * WebSocket onClose handler.
     */
    const onSocketClose = () => {
        console.log('WebSocket connection closed');
        // audioHandlerRef.current.stopStreamingPlayback();
        setIsConnected(false);
    };

    /**
     * WebSocket onError handler.
     * @param {Event} error
     */
    const onSocketError = (error) => {
        console.error('WebSocket error:', error);
        setIsConnected(false);
    };

    /**
     * Processes incoming WebSocket messages.
     * @param {string} messageStr - The JSON string received from the backend.
     */

    const UploadAIChunks = async (aiAudioChunks) => {

        if (aiAudioChunks.length === 0) return;
        let dataToSend = {
            startTime: aiAudioChunks[0].startTime,
            chunks: []
        }
        aiAudioChunks.forEach((chunk) => {
            dataToSend.chunks.push(chunk.audioChunk)
        }

        )
        try {
            let result = await UploadAIAudio(interviewId, email, dataToSend);

        }
        catch (error) {
            console.error('Error uploading AI audio chunks:', error);
        }
        setAiAudioChunks([]);
    }
    useEffect(() => {
        let timerId; // Define timer variable inside useEffect

        // Start the timer
        timerId = setTimeout(() => {
            console.log("Timer has reached 3 seconds!");
            UploadAIChunks(aiAudioChunks);
        }, 3000); // Wait for 3 seconds

        // Cleanup on dependency change or unmount
        return () => {
            clearTimeout(timerId); // Clear the timer

        };
    }, [aiAudioChunks]); // Re-run whenever dependencyVar changes
    const processMessage = (messageStr) => {
        try {
            const message = JSON.parse(messageStr);
            // console.log(message)
            if (message.type === 'response.done') {
                responseDoneReceivedRef.current = true;
            }
            if (message.type === "conversation.item.input_audio_transcription.completed") {
                console.log(message.transcript);
                setUserResponse(message.transcript);
            }
            if (message.type === "response.audio_transcript.done") {
                console.log(message.transcript);
                setAIResponse(message.transcript);
            }
            if (message.type === 'response.audio.delta' && message.delta) {
                const audioChunk = base64ToUint8Array(message.delta);

                let data = {
                    startTime: new Date().toISOString().split('T')[1].slice(0, -1),
                    audioChunk: message.delta
                }
                setAiAudioChunks(prev => [...prev, data]);
                if (responseDoneReceivedRef.current) {
                    // Stop current playback and play new audio
                    audioHandlerRef.current.stopStreamingPlayback();
                    audioHandlerRef.current.startStreamingPlayback();
                    responseDoneReceivedRef.current = false;
                }

                setIsAIPlaying(true);
                audioHandlerRef.current.playChunk(audioChunk);

                // Upload the AI audio chunk
                // uploadAiAudioChunk(message.delta);

                // Adjust as necessary for accurate timing
                setTimeout(() => {
                    setIsAIPlaying(false);
                }, 500);
            }

            // Handle other message types as needed
        } catch (error) {
            console.error('Error parsing JSON message:', error);
        }
    };


    /**
     * Sends the base64-encoded audio chunk to the backend via WebSocket.
     * @param {string} base64Audio - The base64 encoded audio data.
     */
    const sendAudioChunk = async (base64Audio) => {
        if (socketRef.current && !isAIPlaying) {
            const message = {
                type: 'input_audio_buffer.append',
                audio: base64Audio,
                // Include other necessary fields like event_id if required
            };
            socketRef.current.sendMessage(message);
        }
        try {
            const formData = new FormData();
            formData.append('interviewId', interviewId);
            formData.append('email', email);
            formData.append('chunkNumber', chunkNumber); // You need to track chunkNumber
            formData.append('timestamp', new Date().toISOString());
            const audioBlob = new Blob([base64ToUint8Array(base64Audio)], { type: 'audio/wav' });
            formData.append('audioChunk', audioBlob, `human-audio-${chunkNumber}.wav`);
            setChunkNumber(prev => prev + 1); // Increment chunkNumber
            // const response = await uploadMediaChunk('human-audio', formData);
            // setHumanAudioBlockIds(prev => [...prev, response.blockId]);
        } catch (error) {
            console.error('Error uploading human audio chunk:', error);
        }
    };

    /**
     * Converts an ArrayBuffer chunk to a Base64 string.
     * @param {ArrayBuffer} buffer
     * @returns {string}
     */
    const arrayBufferToBase64 = (buffer) => {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };

    /**
     * Introduces a delay.
     * @param {number} ms - Milliseconds to delay
     * @returns {Promise<void>}
     */
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    /**
     * Streams the sample audio to the backend in chunks.
     */
    const sendStartMessage = async () => {
        try {
            try {
                try {
                    
                    let response = await FetchInterviewCredentials(interviewId);
                    if(response.success && !systemPrompt)
                    {
                        setSystemPrompt(response.credentials.system_prompt);

                    }
                    
                }
                catch (err) {

                }
                let systemUpdateMessage = {
                    type: "session.update",
                    system_prompt: systemPrompt,
                    interviewType: "non-coding"
                }
                socketRef.current.sendMessage(systemUpdateMessage);
                try {
                    let data = {
                        interviewId: interviewId,
                        email: email,
                    }

                    const response = await fetch(`https://realtimeapi.easecruit.com/interview/delete`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            // Include any other headers as needed, e.g., authorization
                        },
                        body: JSON.stringify(data),
                    });

                    if (!response.ok) {
                        throw new Error(`API request failed with status ${response.status}`);
                    }

                    const result = await response.json();
                    console.log('Conversation data sent successfully:', result);

                }
                catch (err) {
                    console.log(err);
                }
            } catch (error) {
                console.error('Error sending conversation data to backend API:', error);
            }
            const response = await fetch(startInterviewMp3);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const arrayBuffer = await response.arrayBuffer();

            // Decode the audio data
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const decodedData = await audioContext.decodeAudioData(arrayBuffer);

            // Create an OfflineAudioContext for processing
            const offlineContext = new OfflineAudioContext(
                1, // mono
                Math.ceil(decodedData.duration * 24000), // sampleRate = 24000 Hz
                24000
            );

            // Create a buffer source
            const bufferSource = offlineContext.createBufferSource();
            bufferSource.buffer = decodedData;
            bufferSource.connect(offlineContext.destination);
            bufferSource.start(0);

            // Render the audio
            const renderedBuffer = await offlineContext.startRendering();

            // Get PCM16 data
            const pcm16Data = bufferToPCM16(renderedBuffer.getChannelData(0));

            // Convert PCM16 data to ArrayBuffer
            const pcm16ArrayBuffer = pcm16Data.buffer;

            // Chunking parameters
            const chunkSize = 16000; // 16KB per chunk; adjust as needed
            const totalChunks = Math.ceil(pcm16ArrayBuffer.byteLength / chunkSize);

            for (let i = 0; i < totalChunks; i++) {
                const chunk = pcm16ArrayBuffer.slice(i * chunkSize, (i + 1) * chunkSize);
                const base64 = arrayBufferToBase64(chunk);
                sendAudioChunk(base64);
                await delay(100); // 100ms delay between chunks; adjust as needed
            }

            console.log('Finished sending sample audio');
        } catch (error) {
            console.error('Error in sendStartMessage:', error);
        }
    };

    /**
     * Send conversation data to the backend API when human chat updates.
     */
    useEffect(() => {
        // Only proceed if both userResponse and aiResponse are available
        if (userResponse) {
            const dataToSend = {
                interviewId: interviewId,
                email: email,
                conversation: {
                    user: userResponse,

                },
                isNewInterview: false
            };
            console.log(dataToSend)
            // Send the conversation data to the backend
            sendConversationData(dataToSend);
        }
        if (aiResponse) {
            const dataToSend = {
                interviewId: interviewId,
                email: email,
                conversation: {
                    ai: aiResponse,

                },
                isNewInterview: false
            };
            console.log(dataToSend)
            // Send the conversation data to the backend
            sendConversationData(dataToSend);
        }
        setAIResponse(null)
        setUserResponse(null)
    }, [aiResponse, userResponse]); // Trigger when userResponse updates

    /**
     * Function to send conversation data to the backend API.
     * @param {Object} data - The conversation data object containing aiChat and userChat.
     */
    const sendConversationData = async (data) => {
        try {
            const response = await fetch(`https://realtimeapi.easecruit.com/interview/start`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any other headers as needed, e.g., authorization
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();
            console.log('Conversation data sent successfully:', result);
        } catch (error) {
            console.error('Error sending conversation data to backend API:', error);
        }
    };

    /**
     * Timer Effect: Decrements the timer every second and ends the interview when it reaches zero.
     */
    useEffect(() => {
        if (timer <= 0) {
            return;
        }

        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(interval);
                    EndInterview(); // End interview when timer reaches zero
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000); // 1000ms = 1 second

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [timer]);

    /**
     * Formats the timer from seconds to MM:SS format.
     * @param {number} seconds
     * @returns {string}
     */
    const formatTimer = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
            {/* Display the Timer */}
            {/* You can display the timer here or within the VideoInterface component */}
            {screen === 'starting' && (
                <InterviewStartPage
                    isConnected={isConnected}
                    startRecording={startRecording}
                    sendStartMessage={sendStartMessage}
                    setScreen={setScreen}
                />
            )}
            {screen === 'interview' && (
                <VideoInterface
                    isAISpeaking={isAIPlaying}
                    timer={formatTimer(timer)}
                    onEndInterview={EndInterview}
                    interviewId={interviewId} // Pass EndInterview as a prop
                    email={email}
                    chunkNumber={chunkNumber}
                    audioHandlerRef={audioHandlerRef.current}
                    properTimer={timer}
                    setVideoUploadingStatus={setVideoUploadingStatus}
                    videoUploadingStatus={videoUploadingStatus}
                    screen={screen}

                // Pass the AudioHandler instance
                />
            )}
        </div>
    );
}

export default InterviewInterface;
